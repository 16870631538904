export const supportedDevices = [
    { brand: "iPhone", models: [
        { name: "iPhone XS" },
        { name: "iPhone XS Max" },
        { name: "iPhone XR" },
        { name: "iPhone 11" },
        { name: "iPhone 11 Pro" },
        { name: "iPhone 11 Pro Max" },
        { name: "iPhone SE (2nd generation and later)" },
        { name: "iPhone 12 Mini" },
        { name: "iPhone 12" },
        { name: "iPhone 12 Pro" },
        { name: "iPhone 12 Pro Max" },
        { name: "iPhone 13 Mini" },
        { name: "iPhone 13" },
        { name: "iPhone 13 Pro" },
        { name: "iPhone 13 Pro Max" },
        { name: "iPhone 14" },
        { name: "iPhone 14 Pro" },
        { name: "iPhone 14 Pro Max" },
        { name: "iPhone 15 series" },
        { name: "iPhone 16 series" },
        ],
        topText: "All iPhone models from the iPhone XS and later support eSIM functionality. This includes: ",
    },
    { brand: "iPad", models: [
        { name: "iPad Pro (11-inch, 12.9-inch, 3rd, 4th, and 5th generation)" },
        { name: "iPad Air (3rd, 4th, and 5th generation)" },
        { name: "iPad (7th, 8th, and 9th generation with 4G LTE)" },
        ],
        topText: "If you have a compatible iPad, you can use eSIM for a seamless experience. However, only iPads with cellular capabilities support eSIM: ",
        bottomText1: "* Please ensure your iPad supports 4G to use eSIM functionality. ",
    },
    { brand: "Samsung", models: [
        { name: "Samsung Galaxy S20, S21, S22, and S23 series" },
        { name: "Samsung Galaxy Note 20 series" },
        { name: "Samsung Galaxy Z Fold 3 and Z Fold 4" },
        { name: "Samsung Galaxy Z Flip 3 and Z Flip 4" },
        { name: "Samsung Galaxy A52, A53, A54 5G models" },
        ],
        bottomText1: "Note: The following Samsung devices are not compatible with eSIM: ",
        bottomText2: "* Your device may not support eSIM based on its country of origin. Please verify whether eSIM functionality is available for your device in your location. ",
        bottomList: [
            "Samsung Galaxy S20 FE 4G/5G" ,
            "Samsung S20/S21 (US versions)" ,
            "Galaxy Z Flip 5G (US versions)" ,
            "Samsung Note 20 Ultra (Versions from the US and Hong Kong)" ,
            "Samsung Galaxy Z Fold 2 (Versions from the US and Hong Kong)" ,
        ],
    },
    { brand: "Huawei", models: [
        { name: "Huawei P40" },
        { name: "Huawei P40 Pro" },
        { name: "Huawei Mate 40 Pro" },
        { name: "Huawei Pura 70 Pro" },
    ] },
    { brand: "Oppo", models: [
        { name: "Oppo Find X3/ X3Pro", },
        { name: "Oppo Find X5/ X5 Pro" },
        { name: "Oppo Reno 5G" },
        { name: "Oppo Reno 6 5G" },
        { name: "Oppo A55s 5G" },
        { name: "Oppo Find N3" },
        { name: "Oppo Find N3 Flip" },
        ],
        topText: "Oppo’s lineup of smartphones includes eSIM compatibility: ",
    },
    { brand: "Google Pixel", models: [
        { name: "Google Pixel 3 and 3 XL" },
        { name: "Google Pixel 4 and 4 XL" },
        { name: "Google Pixel 5 and 5a" },
        { name: "Google Pixel 6, 6a" },
        { name: "Google Pixel 7, 7a" },
        { name: "Google Pixel 8, 8 Pro" },
        { name: "Google Pixel 9, 9 Pro" },
        ],
        bottomText1: "* Google Pixel 3 devices from Australia, Japan, and Taiwan are not compatible with eSIM. ",
        bottomText2: "* Google Pixel 3a from South East Asia is not compatible with eSIM. ",
    },
    { brand: "Sony", models: [
        { name: "Sony Xperia 5 IV" },
        { name: "Sony Xperia 1 V" },
        { name: "Sony Xperia Ace III" },
        { name: "Sony Xperia 5 V " },
        { name: "Sony Xperia 1 VI" },
        { name: "Sony Xperia 10 III Lite" },
        { name: "Sony Xperia 10 IV" },
        { name: "Xperia 10V" },
        { name: "Xperia 1 IV" },
    ] },
    { brand: "Honor", models: [
        { name: "Honor 90 " },
        { name: "Honor X8" },
        { name: "Honor 200" },
        { name: "Honor 200 Pro" },
        { name: "Honor Magic V2" },
        { name: "Honor Magic V3" },
        { name: "Honor Magic 4 Pro" },
        { name: "Honor Magic 5 Pro" },
        { name: "Honor Magic 6 Pro" },
    ] },
    { brand: "Other", models: [
        { name: "Gemini PDA" },
        { name: "Fairphone 4 and 5" },
        { name: "DOOGEE V30" },
        { name: "OnePlus Open, 11, and 12" },
        { name: "HAMMER Blade series" },
        { name: "Nokia XR21, X30, G60 5G" },
        { name: "myPhone NOW eSIM" },
        { name: "Vivo X80 Pro, X90 Pro, and others" },
        { name: "OUKITEL WP30 Pro and WP33 Pro" },
        { name: "ZTE Nubia Flip" },
        { name: "TLC 50 5G" },
    ] },
];