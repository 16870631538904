import { Box, IconButton, useTheme, Typography, Menu, MenuItem } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import StorefrontIcon from "@mui/icons-material/Storefront";

const Topbar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const navigate = useNavigate();

    const [user, setUser] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    // Function to check the user login status
    const checkUserStatus = () => {
        const token = localStorage.getItem("jwtToken");
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                setUser(decodedToken);
            } catch (error) {
                console.error("Invalid token:", error);
                setUser(null);
            }
        } else {
            setUser(null);
        }
    };

    // Check user status on component mount and when local storage changes
    useEffect(() => {
        checkUserStatus();
        const intervalId = setInterval(checkUserStatus, 1000);
        return () => clearInterval(intervalId);
    }, []);

    // Handlers
    const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);
    const handleLogout = () => {
        localStorage.removeItem("jwtToken");
        setUser(null);
        setAnchorEl(null);
        navigate("/login");
    };
    const handleProfileClick = () => navigate("/dashboard/profile");
    const handleLogoClick = () => navigate("/");
    const handleStoreClick = () => navigate("/buy-esim");

    return (
        <Box sx={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.05)" }}>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={2}
                sx={{
                    width: "80%",
                    margin: "0 auto",
                    flexDirection: { xs: "column", sm: "row" }, // Stack on small screens
                }}
            >
                {/* Logo and Store */}
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        flexDirection: { xs: "row", sm: "row" }, // Vertical on small screens
                        textAlign: "center",
                    }}
                >
                    {theme.palette.mode === "dark" ? (
                           <img
                                alt="site-logo"
                                width="auto"
                                height="60px"
                                src={`../../assets/esim-brand-logo-dark-mode.png`}
                                onClick={handleLogoClick}
                                style={{ cursor: "pointer" }}
                            />
                        ) : (
                            <img
                                alt="site-logo"
                                width="auto"
                                height="60px"
                                src={`../../assets/esim-brand-logo.png`}
                                onClick={handleLogoClick}
                                style={{ cursor: "pointer" }}
                            /> 
                        )}
                    <Typography
                        variant="body1"
                        color={colors.grey[400]}
                        sx={{ mx: { xs: 2, sm: 2 }, mt: { xs: 1, sm: 0 } }}
                    >
                        |
                    </Typography>
                    <Box
                        onClick={handleStoreClick}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            ml: { xs: 0, sm: 1 },
                            "&:hover": { opacity: 0.8 },
                        }}
                    >
                        <StorefrontIcon />
                        <Typography variant="h6" sx={{ ml: 1 }}>
                            Store
                        </Typography>
                    </Box>
                </Box>

                {/* Icons */}
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={{ xs: "center", sm: "flex-end" }}
                    mt={{ xs: 2, sm: 0 }}
                    flexWrap="wrap"
                >
                    <IconButton onClick={colorMode.toggleColorMode}>
                        {theme.palette.mode === "dark" ? (
                            <DarkModeOutlinedIcon />
                        ) : (
                            <LightModeOutlinedIcon />
                        )}
                    </IconButton>
                    <Typography
                        variant="body1"
                        color={colors.grey[100]}
                        sx={{ mx: { xs: 1, sm: 1 } }}
                    >
                        |
                    </Typography>
                    <Box display="flex" alignItems="center" mt={{ xs: 1, sm: 0 }}>
                        {user ? (
                            <>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    marginLeft="20px"
                                    sx={{ cursor: "pointer" }}
                                >
                                    <IconButton style={{ padding: 0 }}>
                                        <PersonOutlinedIcon style={{ color: colors.grey[100] }} />
                                    </IconButton>
                                    <Typography
                                        variant="h6"
                                        color={colors.grey[100]}
                                        onClick={handleMenuOpen}
                                        sx={{ ml: 1 }}
                                    >
                                        My Account
                                    </Typography>
                                </Box>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                    sx={{
                                        "& .MuiPaper-root": {
                                            backgroundColor: colors.primary[400],
                                            borderRadius: "8px",
                                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                            padding: "8px",
                                            width: "180px",
                                        },
                                    }}
                                >
                                    <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
                                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                                </Menu>
                            </>
                        ) : (
                            <Box display="flex" alignItems="center">
                                <Typography
                                    variant="h6"
                                    color={colors.grey[100]}
                                    onClick={() => navigate("/login")}
                                    sx={{ mx: 1, cursor: "pointer" }}
                                >
                                    LOGIN
                                </Typography>
                                <span>/</span>
                                <Typography
                                    variant="h6"
                                    color={colors.grey[100]}
                                    onClick={() => navigate("/signup")}
                                    sx={{ ml: 1, cursor: "pointer" }}
                                >
                                    SIGNUP
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Topbar;
