import React from 'react';
import { Box, Typography, Divider, List, ListItem, ListItemText } from '@mui/material';
import './TermsAndConditions.css';

const TermsAndConditions = () => {

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="center" alignItems="center">
                <Box className="termsAndConditions">
                    <Typography variant="h2" fontWeight={700} mb={3}>
                        Refund Policy
                    </Typography>

                    <Box className="serviceLeadingSection">
                        <Typography className="spl">
                            This Refund Policy outlines how "Travel Data eSIM" manages refunds for our services. We strive to ensure a seamless experience for our customers and are dedicated to resolving any issues you may encounter.
                        </Typography>
                    </Box>

                    <Box className="serviceLeadingSection">
                        <Box className="serviceInfoContainer">
                            <Typography className="serviceLead">How do I get a refund?</Typography>
                            <Typography className="serviceDetails">We have team members working 24/7 to provide friendly technical support and answer any questions about using your travel eSIM.
                                <br />
                                <br />
                                If you need a refund, please don’t hesitate to reach out to us anytime, and we'll guide you through the process. </Typography>
                            <Divider
                                className={`secionLine lineColorGreen`}
                            />
                            <List className='serviceDetails'>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                    <ListItemText primary={<><strong>Live Chat </strong></>} />
                                </ListItem>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                    <ListItemText primary={<><strong>Email Support</strong></>} />
                                </ListItem>
                            </List>
                        </Box>
                    </Box>

                    <Box className="serviceLeadingSection">
                        <Box className="serviceInfoContainer">
                            <Typography className="serviceLead">What do you need to process my refund? </Typography>
                            <Typography className="serviceDetails">In order to process a refund, our team will ask to see your original receipt (which was emailed to you at the time of purchase). We may also ask for details on why you need a refund. Understanding the reason for your request helps us improve our services. We may also inquire about the reason for your refund request to understand why you were unable to use your Travel Data eSIM service.
                                <br />
                                <br />
                                If there were any issues related to network availability in your region, kindly provide those details so we can address the concern and improve our services for future customers.
                            </Typography>
                            <Divider
                                className={`secionLine lineColorOrange`}
                            />
                        </Box>
                    </Box>

                    <Box className="serviceLeadingSection">
                        <Box className="serviceInfoContainer">
                            <Typography className="serviceLead">Refund Process </Typography>
                            <Typography className="serviceDetails">Leave everything to us! Simply reach out to our Customer Support Team at [email_address] and provide the necessary information and documents we may require. </Typography>
                            <Divider
                                className={`secionLine lineColorPurple`}
                            />
                        </Box>
                    </Box>

                    <Box className="serviceLeadingSection">
                        <Typography className="spl">
                            We will handle your request promptly and keep you updated throughout the process. Your satisfaction is our priority! 
                        </Typography>
                    </Box>

                </Box>
            </Box>
        </Box>
    );
};

export default TermsAndConditions;
