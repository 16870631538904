
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";

const FAQ = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const sections = [
        {
            name: "About Travel Data eSIM",
            questions: [
                "What devices support eSIM?",
                "What is an eSIM?",
                "What is Travel Data eSIM?",
            ]
        },
        {
            name: "Getting Started with Trave Data eSIM",
            questions: [
                // "How do I install an Travel Data eSIM eSIM manually on my Samsung Galaxy device?",
                // "How do I install an Travel Data eSIM eSIM via a QR Code on my Samsung Galaxy device?",
                // "How do I install a Travel Data eSIM eSIM via a QR Code on my iOS device?",
                // "How do I install a Travel Data eSIM eSIM directly on my iOS device?",
                "How do I check if my Android device supports eSIM?",
                "How do I check if my iOS device supports eSIM?",
                "How do I install and set up an eSIM on my iOS device?",
                "When can I install my eSIM?",
                "How do I change my APN settings?",
                "What payment methods do you accept?",
                "How can I find an eSIM for my destination?",
            ]
        },
        {
            name: "Troubleshooting",
            questions: [
                "What Happens When You Use All Your Data?",
                "What to do if I can't delete a Travel Data eSIM eSIM on my Samsung Galaxy S22?",
                "What should I do if the eSIM is stuck activating on my iOS device?",
                "I can't scan my QR Code. What should I do?",
                "Why am I experiencing slow network speeds?",
                "How to Find eSIM QR Code If It’s Lost or Deleted?",
                "Is My Phone “Unlocked” and Able to Use eSIMs?",
                "Can I Use an eSIM and a Physical SIM at The Same Time?",
            ]
        },
        {
            name: "Using & Managing eSIMs",
            questions: [
                "How can I remove an eSIM from my iOS device?",
                "How can I save data usage on my Android device?",
                "How can I save data usage on my iOS device?",
                "Why don't I see 5G in the status bar on my iPhone?",
                "How do I change my eSIM label?",
                "How can I receive SMS and calls while connected with my data-only eSIM?",
                "Can I receive phone calls on my primary number?",
                "Can I reinstall an eSIM?",
                "Can I use tethering (Personal Hotspot)?",
                "Can I install the same eSIM on multiple devices?",
                "What happens to the unused data after the validity period?",
                "How can I check my current data usage?",
                "How do I know which eSIM is using data?",
                "How many Travel Data eSIMs can I install?",
            ]
        },
        {
            name: "Popular questions",
            questions: [
                "How do I check if my Android device supports eSIM?",
                "How do I check if my iOS device supports eSIM?",
                "How do I install and set up an eSIM on my iOS device?",
                "When does my eSIM data package expire?",
                "When can I install my eSIM?",
                "Can I use tethering (Personal Hotspot)?",
                "Can I install the same eSIM on multiple devices?",
                "Is the iPhone 7 eSIM Compatible?",
            ]
        },
    ];

    return (
        <Box
            sx={{
                minHeight: "100vh",
                padding: "40px 40px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "40px",
            }}
        >

            {/* Header Section */}
            <Box
                sx={{
                    padding: "20px 0 0 20px",
                    maxWidth: "800px",
                }}
            >
                <Typography
                    variant="h1"
                    sx={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        color: colors.grey[100],
                    }}
                >
                    FAQ
                </Typography>
            </Box>

            {/* Main Content Section */}
            <Box
                sx={{
                    maxWidth: 900,
                    // display: "grid",
                    // gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
                    gap: "20px",
                }}
            >
                {/* Sections */}
                {sections.map((section, idx1) => (
                    <Box
                        sx={{
                            padding: "20px",
                            borderBottom: "1px solid" + colors.grey[900],
                        }}
                        id={section.name.replace(/ /g, "-")}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 600,
                                mb: "15px",
                            }}
                        >
                            {section.name}
                        </Typography>
                        {section.questions.map((question, idx2) => (
                            <Typography
                                component="a"
                                href={`/page/question/` + (idx1+1) + `-` + (idx2+1)}
                                variant="body1"
                                sx={{
                                    textDecoration: "none",
                                    color: colors.grey[100],
                                    fontSize: "1rem",
                                    lineHeight: "2rem",
                                    "&:hover": {
                                        color: "primary.main", // Optional: Add a hover effect
                                    },
                                }}
                            >
                                {question}
                            <br />
                            </Typography>
                        ))}
                    </Box>
                ))}
            </Box>
        </Box>
    );
}

export default FAQ;