import React from 'react';
import { Box, Typography, Divider, List, ListItem, ListItemText } from '@mui/material';
import './TermsAndConditions.css';

const TermsAndConditions = () => {

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="center" alignItems="center">
                <Box className="termsAndConditions">
                    <Typography variant="h2" fontWeight={700} mb={3}>
                        Cookie Settings
                    </Typography>

                    <Box className="serviceLeadingSection">
                        <Box className="serviceInfoContainer">
                            <Typography className="serviceLead">Cookies Policy for Travel Data eSIM </Typography>
                            <Typography className="serviceDetails">At Travel Data eSIM, we are committed to protecting your privacy and ensuring a seamless browsing experience. This Cookies Policy explains how we use cookies on our website to enhance your user experience. By using our website, you agree to our use of cookies as outlined in this policy. </Typography>
                            <Divider
                                className={`secionLine lineColorBlue`}
                            />
                        </Box>
                    </Box>

                    <Box className="serviceLeadingSection">
                        <Box className="serviceInfoContainer">
                            <Typography className="serviceLead">Summary of This Policy </Typography>
                            <Typography className="serviceDetails">When users first visit our Website, Travel Data eSIM sets strictly necessary cookies on that specific PC, mobile phone, tablet, or other internet-connected device (“Device“). We will only deploy non-necessary cookies on your Device when we have obtained your prior consent to do so.
                                <br />
                                <br />
                                If you choose to consent to our use of non-necessary cookies, you may withdraw your consent to these cookies at any time (see section 8 of this policy for further details). You should also know that some of our cookies collect and process your personal data (e.g. your IP address/location). </Typography>
                            <Divider
                                className={`secionLine lineColorGreen`}
                            />
                        </Box>
                    </Box>

                    <Box className="serviceLeadingSection">
                        <Box className="serviceInfoContainer">
                            <Typography className="serviceLead">What Are Cookies? </Typography>
                            <Typography className="serviceDetails">Cookies are small text files that are stored in your web browser or Device that “remember” information about you and your visit(s) to a website or connection to web application. Certain cookies may also collect personal data about you from your Device.
                                <br />
                                Cookies can be “first party” which are cookies set by us, or “third-party” which are cookies that are placed on your Device by a third party when you visit our Website. Third-party cookies may be placed on your Device by a third party providing a service to us. This information allows us to offer a smoother and more personalized browsing experience. </Typography>
                            <Divider
                                className={`secionLine lineColorOrange`}
                            />
                        </Box>
                    </Box>

                    <Box className="serviceLeadingSection">
                        <Box className="serviceInfoContainer">
                            <Typography className="serviceLead">Why Do We Use Cookies? </Typography>
                            <Typography className="serviceDetails">We use cookies to distinguish you from other users of our website. This allows us to provide you with a more personalized and seamless experience when navigating our website. Cookies also enable us to identify areas for improvement, ensuring our platform meets your expectations.
                                <br />
                                <br />
                                Cookies help the website remember your interactions and preferences over time. This means you won’t need to re-enter your preferences or settings each time you visit our website or move between pages. By using cookies, we aim to enhance your overall browsing experience while maintaining efficiency and convenience. </Typography>
                            <Divider
                                className={`secionLine lineColorPurple`}
                            />
                        </Box>
                    </Box>

                    <Box className="serviceLeadingSection">
                        <Box className="serviceInfoContainer">
                            <Typography className="serviceLead">Types of Cookies Travel Data eSIM Use </Typography>
                            <Typography className="serviceDetails">This section of our policy explains the different types of cookies used on our Website and which cookies do/do not require your consent: </Typography>
                            <Divider
                                className={`secionLine lineColorBlue`}
                            />
                            <List className='serviceDetails'>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <ListItemText primary={<><strong>Strictly Necessary Cookies:</strong> These cookies are essential for the basic functioning of the Travel Data eSIM website. They include cookies that log the type and version of your browser to ensure compatibility and proper display. If these cookies are disabled, certain parts of the website may not operate as intended.</>} />
                                </ListItem>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <ListItemText primary={<><strong>Analytical/Performance Cookies:</strong> These cookies are not essential but help us understand how visitors interact with the Website. They collect anonymous data on visitor behavior, allowing us to improve the Website's performance and usability. We use these insights to offer more relevant content and services. Your consent is required before we use these cookies. </>} />
                                </ListItem>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <ListItemText primary={<><strong>Functional Cookies:</strong> These cookies allow us to recognize returning users and personalize their experience. They help us remember your preferences, such as your user name, language, or region, to offer more tailored content and features. We require your prior consent to use these cookies. </>} />
                                </ListItem>
                            </List>
                        </Box>
                    </Box>

                    <Box className="serviceLeadingSection">
                        <Box className="serviceInfoContainer">
                            <Typography className="serviceLead">Controlling Cookies: Your Preference and Consent </Typography>
                            <Typography className="serviceDetails">When you visit our website, we ask for your consent to use cookies. You can control which types of cookies you want to allow through the cookie banner or through your browser settings. By agreeing to our cookies policy, you consent to the use of cookies as outlined here. </Typography>
                            <Divider
                                className={`secionLine lineColorGreen`}
                            />
                        </Box>
                    </Box>

                    <Box className="serviceLeadingSection">
                        <Box className="serviceInfoContainer">
                            <Typography className="serviceLead">Disabling and Deleting Cookies </Typography>
                            <Typography className="serviceDetails">If you prefer not to allow cookies, you can disable them in your browser settings. Please note that disabling essential cookies may impact your ability to use certain features of our website. You can also delete cookies that have already been set on your device. Each browser offers different options for managing cookie settings, so check your browser’s help section for more information. </Typography>
                            <Divider
                                className={`secionLine lineColorOrange`}
                            />
                        </Box>
                    </Box>

                    <Box className="serviceLeadingSection">
                        <Typography className="spl">
                            For further questions or clarification regarding our Cookies Policy, please contact us at {`<email_address>`}. 
                        </Typography>
                    </Box>

                </Box>
            </Box>
        </Box>
    );
};

export default TermsAndConditions;
