import { Box, TextField, Button, Snackbar, Alert } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../dashboard/Header";
import axios from 'axios';
import { useEffect, useState } from 'react';

const Profile = () => {
    const [userData, setUserData] = useState(null);
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [message, setMessage] = useState(null);
    const [severity, setSeverity] = useState("success");

    useEffect(() => {
        // Retrieve user ID from local storage
        const userId = localStorage.getItem('userid'); // Ensure you store user ID during login

        axios.get(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/viewprofile/${userId}`) // Fetch user data
            .then(response => {
                setUserData(response.data); // Set user data from the response
            })
            .catch(error => {
                console.error("Error fetching user data", error);
            });
    }, []);

    const handleFormSubmit = (values) => {
        const userId = localStorage.getItem('userid'); // Get user ID again for the update request

        axios.put(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/updateprofile/${userId}`, values) // Assume you create this route
            .then(response => {
                console.log("Profile updated successfully:", response.data);
                // show success message or redirect
                setMessage("Profile updated successfully!");
                setSeverity("success");
                setOpenSnackbar(true); // Open the Snackbar
            })
            .catch(error => {
                console.error("Error updating profile", error);
                // show error message or redirect
                setMessage("Error updating profile!");
                setSeverity("error");
                setOpenSnackbar(true); // Open the Snackbar
            });
    };

    const profileSchema = yup.object().shape({
        firstName: yup.string(),
        lastName: yup.string(),
        email: yup.string().email("Invalid Email").required("Email is required"),
        newPassword: yup.string().matches(
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character"
        ),
        currentPassword: yup.string().required("Current password is required"),
    });

    return (
        <>
            <Box m="20px">
                <Header title="Account Information" subtitle="Your account information can be seen here" />
                {userData && (
                    <Formik
                        initialValues={{
                            firstName: userData.firstName,
                            lastName: userData.lastName,
                            email: userData.email,
                            newPassword: "",
                            currentPassword: ""
                        }}
                        validationSchema={profileSchema}
                        onSubmit={handleFormSubmit}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" } }}>
                                    <TextField fullWidth variant="outlined" label="First Name" onBlur={handleBlur} onChange={handleChange} value={values.firstName} name="firstName" error={!!touched.firstName && !!errors.firstName} helperText={touched.firstName && errors.firstName} sx={{ gridColumn: "span 2" }} />
                                    <TextField fullWidth variant="outlined" label="Last Name" onBlur={handleBlur} onChange={handleChange} value={values.lastName} name="lastName" error={!!touched.lastName && !!errors.lastName} helperText={touched.lastName && errors.lastName} sx={{ gridColumn: "span 2" }} />
                                    <TextField fullWidth variant="outlined" label="Email" onBlur={handleBlur} onChange={handleChange} value={values.email} name="email" error={!!touched.email && !!errors.email} helperText={touched.email && errors.email} sx={{ gridColumn: "span 4" }} />
                                    <TextField fullWidth type="password" variant="outlined" label="New Password" onBlur={handleBlur} onChange={handleChange} value={values.newPassword} name="newPassword" error={!!touched.newPassword && !!errors.newPassword} helperText={touched.newPassword && errors.newPassword} sx={{ gridColumn: "span 2" }} />
                                    <TextField fullWidth type="password" variant="outlined" label="Current Password" onBlur={handleBlur} onChange={handleChange} value={values.currentPassword} name="currentPassword" error={!!touched.currentPassword && !!errors.currentPassword} helperText={touched.currentPassword && errors.currentPassword} sx={{ gridColumn: "span 2" }} />
                                </Box>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        marginTop: 3,
                                    }}
                                >Update Profile</Button> {/* Add submit button */}
                            </form>
                        )}
                    </Formik>
                )}
            </Box>

            {/* Alert */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert onClose={() => setOpenSnackbar(false)} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default Profile;
