import React from 'react';
import { tokens } from "../../theme";
import { Box, Typography, List, ListItem, ListItemText, useTheme } from '@mui/material';
import './TermsAndConditions.css';

const TermsAndConditions = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box m="0">
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <Box
                    sx={{
                        backgroundImage: "linear-gradient(to right bottom, rgb(85, 189, 134), rgb(11, 103, 92))",
                    }}
                    p="20px"
                >
                    <Typography variant="h2" fontWeight={700} mb={3} color='white' textAlign='center'>
                        Setup Guides
                    </Typography>

                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: "1rem",
                            lineHeight: "1.8",
                            color: colors.grey[900],
                            opacity: 0.9,
                            textAlign: "center",
                        }}>
                        Welcome to the <strong>Travel Data eSIM Setup Guide</strong>. Here, you will find detailed instructions to help you easily install and activate your eSIM on different devices. Whether you're using a Samsung, iPhone, or another compatible device, we've got you covered with step-by-step instructions for a smooth setup process.
                    </Typography>
                </Box>

                <Box
                    sx={{
                        margin: '20px',
                        padding: '20px',
                        display: 'flex',
                        flexDirection: { md: 'row', xs: 'column' },
                        width: '100%',
                        backgroundImage: "linear-gradient(to left bottom, rgba(255,255,255,0.0), rgba(255,255,255,0.0), rgba(255, 220, 0, 0.3))",
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: "12px",
                        boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.2)",
                    }}
                >
                    {/* Left Part */}
                    <Box
                        sx={{
                            flex: 1,
                            order: { md: 1, xs: 2 },
                            padding: 2,
                        }}
                    >
                        <Typography
                            variant='h6'
                            sx={{
                                color: colors.grey[100],
                                fontSize: '1.5rem',
                            }}
                        >Install and Activate Your eSIM on Samsung </Typography>
                        <Typography
                            variant='body1'
                            sx={{
                                color: colors.grey[100],
                                fontSize: '1rem',
                            }}
                        >To get your Travel Data eSIM working on your Samsung device, follow these steps: </Typography>
                        <br />
                        <Typography
                            variant='h6'
                            fontSize='1rem'
                        >
                            1. Prepare your device
                        </Typography>
                        <List className='serviceDetails'>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={`Make sure your Samsung phone is compatible with eSIM (e.g., Galaxy S20, S21, S22, S23, Galaxy Z Fold, etc.). `} />
                            </ListItem>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={`Ensure you have a stable internet connection (Wi-Fi or mobile data) during the setup. `} />
                            </ListItem>
                        </List>
                        <Typography
                            variant='h6'
                            fontSize='1rem'
                        >
                            2. Open Settings
                        </Typography>
                        <List className='serviceDetails'>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={`Go to the "Settings" app on your Samsung phone. `} />
                            </ListItem>
                        </List>
                        <Typography
                            variant='h6'
                            fontSize='1rem'
                        >
                            3. Select Connections
                        </Typography>
                        <List className='serviceDetails'>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={<>In the "Connections" section, tap on <strong>Mobile Networks</strong>. </>} />
                            </ListItem>
                        </List>
                        <Typography
                            variant='h6'
                            fontSize='1rem'
                        >
                            4. Choose “Add Mobile Plan”
                        </Typography>
                        <List className='serviceDetails'>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={<>Tap <strong>Add Mobile Plan</strong>. If you don’t see this option, select <strong>SIM card manager</strong> and then tap <strong>Add mobile plan</strong>. </>} />
                            </ListItem>
                        </List>
                        <Typography
                            variant='h6'
                            fontSize='1rem'
                        >
                            5. Scan the QR Code
                        </Typography>
                        <List className='serviceDetails'>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={<>Once you click on <strong>Add Mobile Plan</strong>, your phone will prompt you to scan a QR code. Open the QR code you received from Travel Data eSIM and scan it. </>} />
                            </ListItem>
                        </List>
                        <Typography
                            variant='h6'
                            fontSize='1rem'
                        >
                            6. Activate Your eSIM
                        </Typography>
                        <List className='serviceDetails'>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={<>After scanning, your Travel Data eSIM plan will be automatically installed. Follow the on-screen instructions to complete the activation process. </>} />
                            </ListItem>
                        </List>
                        <Typography
                            variant='h6'
                            fontSize='1rem'
                        >
                            7. Check for Activation
                        </Typography>
                        <List className='serviceDetails'>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={<>Once installation is complete, check if your eSIM is activated by verifying your signal icon. </>} />
                            </ListItem>
                        </List>
                    </Box>

                    {/* Right Part */}
                    <Box
                        sx={{
                            flex: 1,
                            order: { md: 2, xs: 1 },
                            padding: 2,
                            maxWidth: { lg: '500px', xs: '300px' },
                        }}
                    >
                        <img
                            width='100%'
                            height='100%'
                            src={`../../assets/samsung.png`}
                            alt="Samsung Phone" />
                    </Box>
                </Box>

                <Box
                    sx={{
                        margin: '20px',
                        padding: '20px',
                        display: 'flex',
                        flexDirection: { md: 'row', xs: 'column' },
                        width: '100%',
                        backgroundImage: "linear-gradient(to right bottom, rgba(255,255,255,0.0), rgba(255,255,255,0.0), rgba(0, 255, 252, 0.3))",
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: "12px",
                        boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.2)",
                    }}
                >
                    {/* Left Part */}
                    <Box
                        sx={{
                            flex: 1,
                            padding: 2,
                            maxWidth: { lg: '500px', xs: '300px' },
                        }}
                    >
                        <img
                            width='100%'
                            height='100%'
                            src={`../../assets/iPhone.png`}
                            alt="Samsung Phone" />
                    </Box>

                    {/* Right Part */}
                    <Box
                        sx={{
                            flex: 1,
                            padding: 2,
                        }}
                    >
                        <Typography
                            variant='h6'
                            sx={{
                                color: colors.grey[100],
                                fontSize: '1.5rem',
                            }}
                        >Install and Activate Your eSIM on iPhone </Typography>
                        <Typography
                            variant='body1'
                            sx={{
                                color: colors.grey[100],
                                fontSize: '1rem',
                            }}
                        >For iPhone users, setting up Travel Data eSIM is a simple process. Here’s how to do it: </Typography>
                        <br />
                        <Typography
                            variant='h6'
                            fontSize='1rem'
                        >
                            1. Ensure Compatibility
                        </Typography>
                        <List className='serviceDetails'>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={`Make sure your iPhone model supports eSIM (iPhone XS, XR, iPhone 11, 12, 13, and later). `} />
                            </ListItem>
                        </List>
                        <Typography
                            variant='h6'
                            fontSize='1rem'
                        >
                            2. Open Settings
                        </Typography>
                        <List className='serviceDetails'>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={<>Go to the <strong>Settings</strong> app on your iPhone. </>} />
                            </ListItem>
                        </List>
                        <Typography
                            variant='h6'
                            fontSize='1rem'
                        >
                            3. Select Cellular or Mobile Data
                        </Typography>
                        <List className='serviceDetails'>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={<>Tap on <strong>Cellular</strong> or <strong>Mobile Data</strong>, depending on your region </>} />
                            </ListItem>
                        </List>
                        <Typography
                            variant='h6'
                            fontSize='1rem'
                        >
                            4. Add Cellular Plan
                        </Typography>
                        <List className='serviceDetails'>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={<>Tap <strong>Add Cellular Plan</strong>. This will prompt you to scan the QR code provided by Travel Data eSIM. </>} />
                            </ListItem>
                        </List>
                        <Typography
                            variant='h6'
                            fontSize='1rem'
                        >
                            5. Scan the QR Code
                        </Typography>
                        <List className='serviceDetails'>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={<>Use your iPhone’s camera to scan the QR code you received from Travel Data eSIM. </>} />
                            </ListItem>
                        </List>
                        <Typography
                            variant='h6'
                            fontSize='1rem'
                        >
                            6. Follow On-Screen Instructions
                        </Typography>
                        <List className='serviceDetails'>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={<>After scanning, your eSIM will be installed automatically. Follow the on-screen prompts to complete the activation. </>} />
                            </ListItem>
                        </List>
                        <Typography
                            variant='h6'
                            fontSize='1rem'
                        >
                            7. Verify Activation
                        </Typography>
                        <List className='serviceDetails'>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={<>Once your eSIM is installed, check the status in <strong>Settings</strong> {`>`} <strong>Cellular</strong> to confirm that it’s active </>} />
                            </ListItem>
                        </List>
                    </Box>
                </Box>

                <Box
                    sx={{
                        margin: '20px',
                        display: 'flex',
                        flexDirection: { lg: 'row', xs: 'column' },
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'stretch',
                        gap: "40px",
                    }}
                >
                    {/* Left Part */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                            padding: "40px",
                            background: `linear-gradient(145deg, ${colors.grey[800]} 10%, ${colors.grey[900]} 90%)`,
                            borderRadius: "12px",
                            boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.2)",
                        }}
                    >
                        <Typography
                            variant='h6'
                            sx={{
                                color: colors.grey[100],
                                fontSize: '1.5rem',
                            }}
                        >Installation with QR Code </Typography>
                        <br />
                        <Typography
                            variant='h6'
                            fontSize='1rem'
                        >
                            1. Receive Your QR Code
                        </Typography>
                        <List className='serviceDetails'>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={<>Upon purchasing your Travel Data eSIM, you will receive a QR code either via email or SMS. </>} />
                            </ListItem>
                        </List>
                        <Typography
                            variant='h6'
                            fontSize='1rem'
                        >
                            2. Open Your Device Settings
                        </Typography>
                        <List className='serviceDetails'>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={<>For most devices, go to <strong>Settings</strong> {`>`} <strong>Mobile Networks</strong> or <strong>Settings</strong> {`>`} <strong>Cellular</strong>. </>} />
                            </ListItem>
                        </List>
                        <Typography
                            variant='h6'
                            fontSize='1rem'
                        >
                            3. Scan the QR Code
                        </Typography>
                        <List className='serviceDetails'>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={<>Look for an option to <strong>Add Mobile Plan</strong> or <strong>Add eSIM</strong>. This will prompt you to scan the QR code you received. </>} />
                            </ListItem>
                        </List>
                        <Typography
                            variant='h6'
                            fontSize='1rem'
                        >
                            4. Confirm and Activate
                        </Typography>
                        <List className='serviceDetails'>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={<>After scanning, follow the on-screen instructions to install and activate your eSIM. You’ll need a stable internet connection (Wi-Fi or mobile data) during this process. </>} />
                            </ListItem>
                        </List>
                    </Box>

                    {/* Right Part */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                            padding: "40px",
                            background: `linear-gradient(145deg, ${colors.grey[800]} 10%, ${colors.grey[900]} 90%)`,
                            borderRadius: "12px",
                            boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.2)",
                        }}
                    >
                        <Typography
                            variant='h6'
                            sx={{
                                color: colors.grey[100],
                                fontSize: '1.5rem',
                            }}
                        >Manual Installation </Typography>
                        <Typography
                            variant='body1'
                            sx={{
                                color: colors.grey[100],
                                fontSize: '1rem',
                            }}
                        >If you prefer to manually input the eSIM details, follow these steps: </Typography>
                        <br />
                        <Typography
                            variant='h6'
                            fontSize='1rem'
                        >
                            1. Open Device Settings
                        </Typography>
                        <List className='serviceDetails'>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={<>Go to <strong>Settings</strong> {`>`} <strong>Mobile Networks</strong> or <strong>Settings</strong> {`>`} <strong>Cellular</strong>. </>} />
                            </ListItem>
                        </List>
                        <Typography
                            variant='h6'
                            fontSize='1rem'
                        >
                            2. Select “Add Mobile Plan”
                        </Typography>
                        <List className='serviceDetails'>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={<>Tap <strong>Add Mobile Plan</strong>, then choose <strong>Enter Details Manually</strong>. </>} />
                            </ListItem>
                        </List>
                        <Typography
                            variant='h6'
                            fontSize='1rem'
                        >
                            3. Enter the eSIM Details
                        </Typography>
                        <List className='serviceDetails'>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={<>You’ll be asked to input the details provided by Travel Data eSIM, including the activation code and other necessary information. </>} />
                            </ListItem>
                        </List>
                        <Typography
                            variant='h6'
                            fontSize='1rem'
                        >
                            4. Activate Your eSIM
                        </Typography>
                        <List className='serviceDetails'>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={<>After entering the details, your eSIM will be activated. Ensure your device is connected to the internet during this process. </>} />
                            </ListItem>
                        </List>
                    </Box>
                </Box>

            </Box>
        </Box>
    );
};

export default TermsAndConditions;
