import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, Grid, FormControl, InputLabel, Select, MenuItem, Pagination } from '@mui/material';
import PackageCard from '../../components/packageCard';
import { useNavigate, useSearchParams } from 'react-router-dom';

const ShopPage = () => {
    const [searchParams] = useSearchParams();
    const [products, setProducts] = useState([]); // All fetched products
    const [filteredProducts, setFilteredProducts] = useState([]); // Products filtered by region/country
    const [regions, setRegions] = useState([]); // Regions for filter dropdown
    const [countries, setCountries] = useState([]); // Countries for filter dropdown
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const navigate = useNavigate();

    const country = searchParams.get('country');

    const createSlug = (text) =>
        text.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');

    useEffect(() => {
        const fetchData = async () => {
            // Initially fetch country products and regional products
            await fetchProducts(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/regional-products`);
            await fetchProducts(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/country-products`);
            setSelectedCountry(country || '');
            if (window.location.search.includes('country=')) {
                const url = new URL(window.location.href);
                url.searchParams.delete('country');
                window.history.replaceState(null, '', url.toString());
            }
        }
        fetchData();
    }, [country]);

    useEffect(() => {
        // Fetch all products and filters based on the selected region or country
        if (selectedRegion) {
            fetchProducts(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/regional-products?region=${selectedRegion}`);
        } else if (selectedCountry) {
            fetchProducts(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/country-products?country=${selectedCountry}`);
        }
    }, [selectedRegion, selectedCountry]);

    // Fetch products and filters from API
    const fetchProducts = async (url) => {
        try {
            const response = await axios.get(url);
            const fetchedProducts = response.data;

            // console.log(fetchedProducts); // Log the products to check if `region` exists and is correct
            setProducts(fetchedProducts);
            fetchFilters(fetchedProducts);
            setPage(1); // Reset to page 1

            // Apply the selected filters
            applyFilters(fetchedProducts, selectedRegion, selectedCountry);
        } catch (error) {
            console.error('Error fetching products', error);
        }
    };

    // Fetch filters based on products
    const fetchFilters = (products) => {
        // Extract unique regions from country and regional products
        const regionsSet = new Set(
            products
                .filter((product) => {
                    // For country-based products, get region from product.country.region
                    return product.region || product.country?.region;
                })
                .map((product) => {
                    // If region is part of the country, use product.country.region
                    return product.region || product.country?.region;
                })
        );

        const countriesSet = new Set(
            products
                .filter((product) => product.country?.name && product.country.name.trim() !== '')
                .map((product) => product.country.name.trim())
        );


        if (countriesSet.size > 0) {
            setCountries([...countriesSet].sort()); // Set countries dropdown
        } else {
            const mergedRegions = [...regions, ...[...regionsSet].filter(item => !regions.includes(item))].sort();
            setRegions(mergedRegions); // Set regions dropdown
        }
    };


    // Apply filters based on selected region/country
    const applyFilters = (productsList, region, country) => {
        let filtered = productsList;

        if (region) {
            filtered = filtered.filter((product) => product.region === region);
        }
        if (country) {
            filtered = filtered.filter((product) => product.country?.name === country);
        }

        setFilteredProducts(filtered);
        setTotalPages(Math.ceil(filtered.length / 16)); // Pagination logic
    };

    const handleRegionChange = (event) => {
        setSelectedRegion(event.target.value);
        setSelectedCountry(''); // Reset country filter when region is selected
    };

    const handleCountryChange = (event) => {
        setSelectedCountry(event.target.value);
        setSelectedRegion(''); // Reset region filter when country is selected
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleBuyNow = (id, packageName) => {
        const slug = createSlug(packageName);
        navigate(`/product/${id}-${slug}`);
    };

    const paginatedProducts = filteredProducts.slice((page - 1) * 16, page * 16);

    return (
        <Box p={3}>
            <Typography variant="h4" gutterBottom>
                eSIM Packages
            </Typography>

            {/* Filters */}
            <Box display="flex" justifyContent="space-between" mb={3}>
                <FormControl sx={{ width: '45%' }}>
                    <InputLabel>Country</InputLabel>
                    <Select value={selectedCountry} onChange={handleCountryChange}>
                        <MenuItem value="">All Countries</MenuItem>
                        {countries.map((country) => (
                            <MenuItem key={country} value={country}>
                                {country}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{ width: '45%' }}>
                    <InputLabel>Region</InputLabel>
                    <Select value={selectedRegion} onChange={handleRegionChange}>
                        <MenuItem value="">All Regions</MenuItem>
                        {regions.map((region) => (
                            <MenuItem key={region} value={region}>
                                {region}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            {/* Product Grid */}
            <Grid container spacing={3}>
                {paginatedProducts.map((product) => (
                    <Grid item xs={12} sm={6} md={3} key={product.id}>
                        <PackageCard product={product} onBuyNow={handleBuyNow} />
                    </Grid>
                ))}
            </Grid>

            {/* Pagination */}
            <Box display="flex" justifyContent="center" mt={3}>
                <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                />
            </Box>
        </Box>
    );
};

export default ShopPage;
