import { Box, Typography, useTheme } from "@mui/material";
// import { useContext } from "react";
import { /*ColorModeContext,*/ tokens } from "../../theme";
import { useNavigate, Link } from "react-router-dom";

const Footer = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    // const colorMode = useContext(ColorModeContext);
    const navigate = useNavigate();

    const handleLogoClick = () => {
        navigate("/");
    };

    return (
        <>
            {/* Main Footer */}
            <Box
                sx={{
                    boxShadow: "15px 5px 10px 8px rgba(0, 0, 0, 0.05)",
                    padding: "40px 0px 10px 0px",
                }}
            >
                <Box
                    sx={{
                        width: { xs: "95%", sm: "80%" },
                        margin: "0 auto",
                    }}
                >
                    {/* Top Footer */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" }, // Column on mobile, row on larger screens
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            gap: { xs: 2, sm: 4 },
                            p: 2,
                        }}
                    >
                        {/* Logo */}
                        <Box
                            flexShrink={0}
                            onClick={handleLogoClick}
                            sx={{
                                cursor: "pointer",
                                textAlign: { xs: "center", sm: "left" },
                                flexBasis: { xs: "100%", sm: "20%" },
                            }}
                        >
                           {theme.palette.mode === "dark" ? (
                                <img
                                    alt="site-logo"
                                    width="auto"
                                    height="60px"
                                    src={`../../assets/esim-brand-logo-dark-mode.png`}
                                    onClick={handleLogoClick}
                                    style={{ cursor: "pointer" }}
                                />
                            ) : (
                                <img
                                    alt="site-logo"
                                    width="auto"
                                    height="60px"
                                    src={`../../assets/esim-brand-logo.png`}
                                    onClick={handleLogoClick}
                                    style={{ cursor: "pointer" }}
                                /> 
                        )}
                        </Box>

                        {/* Footer Links */}
                        <Box
                            sx={{
                                display: "grid",
                                gridTemplateColumns: {
                                    xs: "repeat(2, 1fr)", // 2x2 on mobile
                                    sm: "repeat(4, 1fr)", // 4x4 on larger screens
                                },
                                gap: 2,
                                flexBasis: { xs: "100%", sm: "80%" },
                                justifyItems: "start",
                            }}
                        >
                            {[
                                {
                                    title: "Popular Countries",
                                    links: [
                                        { label: "Sri Lanka", to: "/buy-esim?country=Sri%20Lanka" },
                                        { label: "Thailand", to: "/buy-esim?country=Thailand" },
                                        { label: "India", to: "/buy-esim?country=India" },
                                        { label: "UK", to: "/buy-esim?country=United%20Kingdom" },
                                    ],
                                },
                                // {
                                //     title: "Partner with Us",
                                //     links: [
                                //         { label: "Affiliate", to: "/affiliate" },
                                //         { label: "Reseller", to: "/reseller" },
                                //         { label: "API Partner", to: "/api-partner" },
                                //         { label: "Corporate", to: "/corporate" },
                                //     ],
                                // },
                                {
                                    title: "About Us",
                                    links: [
                                        { label: "Our Story", to: "/page/our-story" },
                                        { label: "Why choose us", to: "/page/why-choose-us" },
                                        { label: "Impact", to: "/page/impact" },
                                        { label: "Contact", to: "page/contact" },
                                    ],
                                },
                                {
                                    title: "More Info",
                                    links: [
                                        { label: "What is an eSIM", to: "/page/what-is-an-esim" },
                                        { label: "Compatible Devices", to: "/page/compatible-devices" },
                                        { label: "Setup Guides", to: "/page/setup-guides" },
                                        { label: "FAQ", to: "/page/faq" },
                                    ],
                                },
                                {
                                    title: "Legal",
                                    links: [
                                        { label: "Terms and Conditions", to: "/page/terms" },
                                        { label: "Privacy Policy", to: "/page/privacy-policy" },
                                        { label: "Cookie Settings", to: "/page/cookie-settings" },
                                        { label: "Refund Policy", to: "/page/refund-policy" },
                                    ],
                                },
                            ].map((section, index) => (
                                <Box key={index}>
                                    <Typography
                                        variant="h6"
                                        sx={{ color: colors.grey[100] }}
                                    >
                                        {section.title}
                                    </Typography>
                                    <Box>
                                        {section.links.map((link, idx) => (
                                            <Typography key={idx} sx={{ color: colors.grey[100] }}>
                                                <Link
                                                    to={link.to}
                                                    style={{
                                                        textDecoration: "none",
                                                        color: colors.grey[100], // Text color using theme
                                                    }}
                                                >
                                                    {link.label}
                                                </Link>
                                            </Typography>
                                        ))}
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Box>

                    <hr style={{ margin: "20px 0", borderColor: colors.grey[300] }} />

                    {/* Bottom Footer */}
                    <Box
                        sx={{
                            py: 0,
                            px: 8,
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" },
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        {/* Developed By */}
                        <Typography
                            variant="body1"
                            sx={{
                                textAlign: { xs: "center", sm: "left" },
                                mb: { xs: 2, sm: 0 },
                                color: colors.grey[100],
                            }}
                        >
                            Developed by{" "}
                            <strong>
                                <a 
                                    href="https://www.codexlk.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        textDecoration: "none",
                                        color: colors.grey[100],
                                    }}
                                >
                                    CODEX
                                </a>
                            </strong>
                        </Typography>

                        {/* Payment Methods */}
                        <Box
                            sx={{
                                display: "flex",
                                gap: "10px",
                                justifyContent: { xs: "center", sm: "flex-end" },
                                width: { xs: "100%", sm: "auto" },
                            }}
                        >
                            <img
                                src="../../assets/footer-payment-methods.png"
                                alt="payment-methods"
                                width="180"
                                style={{ maxWidth: "100%" }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default Footer;
