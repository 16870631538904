import { useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material"
import { Link, useNavigate } from 'react-router-dom';
import { tokens } from "../../theme";
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import LogoutIcon from '@mui/icons-material/Logout';
import axios from 'axios';
 
// menu item component
const Item = ({title, to, icon, selected, setSelected}) =>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return(
        <MenuItem 
            active={selected === title} 
            style={{color: colors.grey[100]}} 
            onClick={() => setSelected(title)} 
            icon={icon}
            component={<Link to={to} />}
        >
            <Typography>{title}</Typography>
            
        </MenuItem>
    );
};
 

const ProSidebar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selected, setSelected] = useState("Dashboard");
    const navigate = useNavigate();

    const [user, setUser] = useState(null);
    // const [anchorEl, setAnchorEl] = useState(null);

    // Fetch user profile data
    useEffect(() => {
        const userId = localStorage.getItem('userid'); // Retrieve user ID from local storage
        if (userId) {
            axios.get(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/viewprofile/${userId}`)
                .then(response => {
                    setUser(response.data); // Set user data from the API response
                })
                .catch(error => {
                    console.error("Error fetching user data", error);
                });
        }
    }, []);

    useEffect(() => {
        const mdBreakpoint = theme.breakpoints.values.md;

        const handleResize = () => {
            // Check if screen width is less than the 'md' breakpoint
            const isSmallScreen = window.innerWidth < mdBreakpoint;
            setIsCollapsed(isSmallScreen);
        };

        // Initial check
        handleResize();

        // Add event listener for resize
        window.addEventListener("resize", handleResize);

        // Cleanup listener on unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [theme.breakpoints.values.md]); // Re-run if the breakpoint value changes

    const handleLogout = () => {
        localStorage.removeItem("jwtToken"); // Remove jwtToken on logout
        setUser(null); // Clear user state
        // setAnchorEl(null);
        navigate("/login");
      };

    return (
        <Box
        sx={{
            "& .ps-sidebar-container": {
            background: "transparent !important",
            boxShadow: "2px 10 5px rgba(0, 0, 0, 0.1) !important",
            },
            "& .ps-menu-button": {
            backgroundColor: "transparent !important",
            },
            "& .pro-inner-item": {
            padding: "5px 35px 5px 20px !important",
            },
            "& .ps-menu-button:hover": {
            color: "#868dfb !important",
            },
            "& .ps-menu-button.active": {
            color: "#6870fa !important",
            },
        }}
        >
        <Sidebar collapsed={isCollapsed} style={{ height: '100%' }}>
            <Menu iconShape="square">
            {/* LOGO AND MENU ICON */}
            <MenuItem
                onClick={() => setIsCollapsed(!isCollapsed)}
                icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                style={{
                margin: "10px 0 20px 0",
                color: colors.grey[100],
                }}
            >
                {!isCollapsed && (
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    ml="5px"
                    mt="40px"
                >

                    <Box>
                        <Typography variant="h4" color={colors.grey[100]}>
                        TravelData eSim
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                        <MenuOutlinedIcon />
                        </IconButton>
                    </Box>
                    
                </Box>
                )}
            </MenuItem>

            {!isCollapsed && (
                <Box mb="25px">
                <Box display="flex" justifyContent="center" alignItems="center">
                    <img
                        alt="profile-user"
                        width="200px"
                        height="200px"
                        src={`../../assets/user.png`}
                        style={{ cursor: "pointer", borderRadius: "50%" }}
                    />
                </Box>
                <Box textAlign="center">
                    <Typography variant="h2" color={colors.grey[100]} fontWeight="bold" sx={{ m: "10px 0 0 0" }}>
                        {user ? `${user.firstName} ${user.lastName}` : 'Loading...'}
                    </Typography>
                    <Typography variant="h5" color={colors.greenAccent[500]}>
                    Traveler
                    </Typography>
                </Box>
                </Box>
            )}

            {/* Menu Items */}
            <Box paddingLeft={isCollapsed ? undefined : "1%"}>
                {/* <Item
                    title="Dashboard"
                    to="/dashboard"
                    icon={<HomeOutlinedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                /> */}
                <Item
                    title="Profile"
                    to="/dashboard/profile"
                    icon={<PersonOutlinedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                />
                <Item
                    title="Orders"
                    to="/dashboard/order"
                    icon={<ReceiptOutlinedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                />
                {/* <Item
                    title="Invoice Balances"
                    to="/dashboard/invoices"
                    icon={<DescriptionIcon />}
                    selected={selected}
                    setSelected={setSelected}
                /> */}
                <Item
                    title="Check Usage"
                    to="/dashboard/check_usage"
                    icon={<DescriptionIcon />}
                    selected={selected}
                    setSelected={setSelected}
                />
                <MenuItem
                    onClick={handleLogout}
                    icon={<LogoutIcon />}
                    style={{
                      color: colors.redAccent[500],
                      bottom: "0",
                      width: "100%",
                    }}
                >
                    <Typography>Logout</Typography>
                </MenuItem>
            </Box>
                
            </Menu>
            </Sidebar>
        </Box>
    );
};

export default ProSidebar;