import React, { useState } from "react";
import { tokens } from "../../theme";
import './TermsAndConditions.css';
import {
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Collapse,
    Typography,
    useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { supportedDevices } from "../../data/supportedDevices";


const TermsAndConditions = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [open, setOpen] = useState({});

    const handleToggle = (brand) => {
        setOpen((prevState) => ({
            ...prevState,
            [brand]: !prevState[brand],
        }));
    };

    const renderBottomText = (bottomText) => {
        if (!bottomText) return null;

        if (typeof(bottomText) === "string") {
            return (
                <Typography sx={{ mt: 1 }} variant="body1" color="textSecondary">
                    {bottomText}
                </Typography>
            );
        }

        if (typeof(bottomText) === "object") {
            return (
                <Box component="ul" sx={{ mt: 1, pl: 6 }}>
                    {bottomText.map((item, index) => (
                        <Box component="li" key={index}>
                            <Typography variant="body1" color="textSecondary">
                                {item}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            );
        }

        return null; // In case of unexpected type
    };

    const faq = [
        { question: "Does my device need to be unlocked to use eSIM? ", answer: "Yes, your phone should be unlocked to use Travel Data eSIM, allowing you to switch to our service without restrictions. " },
        { question: "Is the iPhone 10 compatible with eSIM? ", answer: "Unfortunately, the iPhone 10 does not have eSIM support. The first Apple devices to feature eSIM were the iPhone XR and iPhone XS, released in 2018. Earlier models do not support this feature. " },
        { question: "Are there any tablets that support eSIM? ", answer: "Yes, several tablets, including the Apple iPad Pro and iPad Air models, support eSIM functionality. " },
        { question: "Is the Huawei P40 Pro+ compatible with eSIM? ", answer: "Yes, the Huawei P40 Pro+ is eSIM-compatible, allowing users to take advantage of eSIM features. " },
        { question: "Can I use eSIM on Google Pixel phones? ", answer: "Yes, Google Pixel 3 and newer models are compatible with eSIM. " },
    ]

    return (
        <Box m="0">
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        alignItems: "center",
                        backgroundImage: "linear-gradient(to right bottom, rgba(196,251,255,.5), rgba(179,220,255,.5))",
                    }}
                    p="20px"
                >
                    <Typography variant="h2" fontWeight={700} mb={3} textAlign='center'>
                        eSIM Compatible Devices
                    </Typography>

                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: "1rem",
                            lineHeight: "1.8",
                            color: colors.grey[100],
                            opacity: 0.9,
                            textAlign: "center",
                            maxWidth: "94ch", 
                            overflowWrap: "break-word", 
                            textOverflow: "ellipsis"
                        }}>
                        At Travel Data eSIM, we are dedicated to providing seamless connectivity through our innovative eSIM technology. Our service allows you to stay connected on the go without the hassle of physical SIM cards. 
                    </Typography>
                </Box>

                <Box
                    m={3}
                >
                    <Typography
                        variant="body1"
                        fontSize="1rem"
                    >
                        <strong>* Important:</strong> your phone must also be <Typography component="span" variant="body1" fontSize="1rem" sx={{ color: colors.redAccent[500] }}>carrier-unlocked</Typography> to use eSIM. 
                    </Typography>
                </Box>

                <Box sx={{ width: "100%", maxWidth: 700, margin: "0 auto", mt: 4 }}>
                    <List>
                        {supportedDevices.map((device) => (
                            <Box key={device.brand} sx={{ 
                                mb: 2,
                                pb: 2,
                                borderBottom: "1px solid " + colors.grey[900],
                            }}>
                                <ListItemButton onClick={() => handleToggle(device.brand)}>
                                    <ListItemText primaryTypographyProps={{ fontSize: "1.25rem", fontWeight: "500" }} primary={device.brand} />
                                    {open[device.brand] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </ListItemButton>
                                <Collapse in={open[device.brand]} timeout="auto" unmountOnExit>
                                    <Box sx={{ px: 2 }}>
                                        {device.topText && (
                                            <Typography sx={{ mb: 1 }} variant="body1" color="textSecondary">
                                                {device.topText}
                                            </Typography>
                                        )}
                                        <List component="div" disablePadding>
                                            {device.models.map((model) => (
                                                <ListItem key={model.name} sx={{ pl: 4 }}><span className='bulletPoint'>•</span>
                                                    <ListItemText primary={model.name} />
                                                </ListItem>
                                            ))}
                                        </List>
                                        {renderBottomText(device.bottomText1)}
                                        {renderBottomText(device.bottomList)}
                                        {renderBottomText(device.bottomText2)}
                                    </Box>
                                </Collapse>
                            </Box>
                        ))}
                    </List>
                </Box>

                <Box
                    m={3}
                >
                    <Typography
                        variant="h6"
                        fontSize="2rem"
                        textAlign="center"
                    >
                        Frequently Asked Questions About eSIM-Compatible Phones 
                    </Typography>
                </Box>

                <Box sx={{ width: "100%", maxWidth: 700, margin: "0 auto", mt: 4 }}>
                    <List>
                        {faq.map((faq, idx) => (
                            <Box key={faq.question} sx={{ 
                                mb: 2,
                                py: 2,
                                border: "1px solid " + colors.grey[900],
                                borderRadius: 4
                            }}>
                                <ListItemButton onClick={() => handleToggle(faq.question)}>
                                    <ListItemText primaryTypographyProps={{ fontSize: "1.25rem", fontWeight: "500" }} primary={(idx+1) + '. ' + faq.question} />
                                    {open[faq.question] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </ListItemButton>
                                <Collapse in={open[faq.question]} timeout="auto" unmountOnExit>
                                    <Box sx={{ px: 2 }}>
                                        {faq.answer && (
                                            <Typography sx={{ mb: 1, fontSize: "1rem" }} variant="body1" color="textSecondary">
                                                {faq.answer}
                                            </Typography>
                                        )}
                                    </Box>
                                </Collapse>
                            </Box>
                        ))}
                    </List>
                </Box>

            </Box>
        </Box>
    );
};

export default TermsAndConditions;
