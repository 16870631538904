import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import PublicIcon from "@mui/icons-material/Public";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import SecurityIcon from "@mui/icons-material/Security";
import SavingsIcon from "@mui/icons-material/Savings";
import EcoIcon from "@mui/icons-material/EnergySavingsLeaf";

const WhatIsESIM = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const cards = [
        {
            title: "Instant Delivery and Activation:",
            text: "When you purchase an eSIM, it is delivered to your email almost instantly. Activation is quick and typically takes only a few minutes, allowing you to get connected without delay. ",
            icon: <FlashOnIcon fontSize="large" color="secondary" />,
        },
        {
            title: "Multi-Country Coverage:",
            text: "If you're traveling internationally, you can choose a regional eSIM plan that provides coverage across multiple countries. This means you won’t need to switch to different eSIMs as you move from one country to another, simplifying your travel experience. ",
            icon: <PublicIcon fontSize="large" color="secondary" />,
        },
        {
            title: "Keep Your Regular Phone Number:",
            text: "With an eSIM, you can use both your primary line and a secondary line simultaneously if your phone supports dual SIM functionality. This allows you to maintain your regular phone number while accessing local data services. ",
            icon: <PhoneEnabledIcon fontSize="large" color="secondary" />,
        },
        {
            title: "Impossible to Lose:",
            text: "Unlike physical SIM cards that are small and easy to misplace, eSIMs are entirely digital. Once activated, there’s no risk of losing your eSIM, ensuring you remain connected at all times. ",
            icon: <SecurityIcon fontSize="large" color="secondary" />,
        },
        {
            title: "Cost Savings:",
            text: "eSIMs can be significantly more affordable than traditional options like data roaming or renting pocket WiFi devices. This cost-effective solution allows you to enjoy data services without breaking the bank.",
            icon: <SavingsIcon fontSize="large" color="secondary" />,
        },
        {
            title: "Environmentally Friendly:",
            text: "Since eSIMs are digital, they eliminate the need for plastic SIM cards, reducing environmental waste. Choosing an eSIM is a step towards more sustainable travel practices.",
            icon: <EcoIcon fontSize="large" color="secondary" />,
        },
    ];

    const iPhoneSteps = [
        <>Go to <strong>Settings</strong>.</>,
        <>Tap <strong>Cellular</strong> (or <strong>Mobile Data</strong>).</>,
        <>Select <strong>Add eSIM</strong>.</>,
        <>Choose <strong>Use QR Code</strong>.</>,
        <>Scan the QR code and follow the prompts to complete the setup.</>,
    ];

    const androidSteps = [
        <>Open <strong>Settings</strong>.</>,
        <>Go to <strong>Connections</strong>.</>,
        <>Tap <strong>SIM Manager</strong>.</>,
        <>Select <strong>Add eSIM</strong>, then <strong>Scan QR Code</strong>.</>,
        <>Scan the QR code and follow the prompts to finish.</>,
    ];

    return (
        <Box
            sx={{
                width: "100%",
                minHeight: "100vh",
                padding: "40px 20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "40px",
            }}
        >
            {/* Header Section */}
            <Box
                sx={{
                    textAlign: "center",
                    padding: "20px",
                    maxWidth: "800px",
                }}
            >
                <Typography
                    variant="h1"
                    sx={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        color: colors.grey[100],
                    }}
                >
                    What is an eSIM
                </Typography>
            </Box>

            {/* Main Content Section */}
            <Box
                sx={{
                    maxWidth: "1200px",
                    display: "grid",
                    gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
                    gap: "40px",
                }}
            >
                {/* Left Column */}
                <Box>
                    <Typography
                        variant="h2"
                        sx={{
                            fontWeight: "bold",
                            marginBottom: "20px",
                            color: colors.grey[100],
                        }}
                    >
                        What is an eSIM Card, and How Does It Work?
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: "1.1rem",
                            lineHeight: "1.8",
                            marginBottom: "20px",
                            color: colors.grey[100],
                        }}
                    >
                        An eSIM (embedded SIM) card is a digital version of a traditional SIM card that is
                        built directly into your device. Unlike a physical SIM card, which you insert and
                        remove from your phone, an eSIM allows you to store multiple carrier profiles and
                        switch between them without needing to physically change a card. The embedded chip
                        in your device holds all the necessary information to connect to cellular networks,
                        enabling you to make calls, send messages, and use mobile data as soon as you activate
                        your eSIM.
                    </Typography>
                    <Typography
                        variant="h2"
                        sx={{
                            fontWeight: "bold",
                            marginBottom: "20px",
                            color: colors.grey[100],
                        }}
                    >
                        eSIM vs. SIM Cards
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: "1.1rem",
                            lineHeight: "1.8",
                            color: colors.grey[100],
                        }}
                    >
                        The key distinction between eSIMs and traditional SIM cards is that eSIMs are embedded
                        directly into your device and can be programmed via software, while traditional SIM
                        cards are removable.
                    </Typography>
                </Box>

                {/* Right Column */}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "12px",
                        boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.2)",
                        overflow: "hidden", // Ensures cropping happens inside the Box
                    }}
                >
                    <img
                        src={`../../assets/what-is-esim-cover1.jpg`}
                        alt="A traveller using an iPhone"
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover", // Ensures the image covers the Box while cropping
                        }}
                    />
                </Box>
            </Box>

            {/* paragraph under main content */}
            <Box
                sx={{
                    maxWidth: "1200px",
                }}
            >
                <Typography
                    variant="body1"
                    sx={{
                        fontSize: "1.1rem",
                        lineHeight: "1.8",
                        color: colors.grey[100],
                    }}
                >
                    eSIMs are not supported by most older phone models, and an increasing number of new devices are eliminating physical SIM card slots. For instance, all iPhone 14 models released in the US exclusively use eSIM technology.
                </Typography>
            </Box>

            {/* Secondary Header Section */}
            <Box
                sx={{
                    maxWidth: "1200px",
                }}
            >
                <Typography
                    variant="h1"
                    sx={{
                        fontWeight: "700",
                    }}
                >
                    Experience the convenience and flexibility of eSIMs today!
                </Typography>
            </Box>

            {/* Secondary Content Section */}
            <Box
                sx={{
                    background: colors.primary[100],
                    p: 6,
                    width: "100%",
                }}
            >
                <Typography
                    variant="h2"
                    sx={{
                        color: colors.primary[900],
                        fontWeight: 700,
                        textAlign: "center",
                        m: 1,
                        mb: 3,
                    }}
                >
                    Why should I use an eSIM?
                </Typography>
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: {
                            lg: "repeat(3, 1fr)", // 3 columns on lg and larger
                            md: "repeat(2, 1fr)", // 2 columns on md
                            sm: "repeat(1, 1fr)", // 1 column on sm and smaller
                        },
                        gap: 6, // Add spacing between boxes
                    }}
                >
                    {cards.map((card, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                height: "100%",
                            }}
                        >
                            {card.icon}
                            <Typography
                                variant="body1"
                                sx={{
                                    mt: 1,
                                    color: colors.primary[900],
                                    fontSize: "1rem",
                                }}
                            >
                                {card.title}
                            </Typography>
                            <br />
                            <Typography
                                variant="body2"
                                sx={{
                                    color: colors.grey[700],
                                    textAlign: "justify",
                                }}
                            >
                                {card.text}
                            </Typography>
                        </Box>
                    ))}
                </Box>

            </Box>

            {/* Bottom Content Section */}
            <Box
                sx={{
                    width: "100%",
                    maxWidth: "1200px",
                    marginLeft: -5,
                }}
            >
                <Typography
                    variant="h2"
                    sx={{
                        fontWeight: 700,
                        textAlign: "center",
                        m: 1,
                    }}
                >
                    How to Activate an eSIM
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: "center",
                        fontSize: "1rem",
                    }}
                >
                    Activating your eSIM is quick and easy! After purchasing and receiving your QR code, follow these steps based on your device:
                </Typography>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: { md: "row", xs: "column" },
                        gap: 6, // Add spacing between boxes
                        m: 3,
                    }}
                >
                    <Box
                        sx={{
                            display: "grid",
                            width: { md: "50%", xs: "100%" },
                            padding: 3,
                            paddingLeft: 5,
                            background: `linear-gradient(145deg, ${colors.grey[800]} 10%, ${colors.grey[900]} 90%)`,
                            borderRadius: "12px",
                            boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.2)",
                        }}
                    >
                        <img src={`../../assets/ios-logo.png`}
                            alt="iOS Logo"
                            style={{
                                justifySelf: "center",
                                width: "128px",
                                height: "128px",
                                objectFit: "cover", // Ensures the image covers the Box while cropping
                            }}
                        />
                        <Typography
                            variant="h3"
                            sx={{ fontWeight: 700, }}
                        >
                            For iOS:
                        </Typography>
                        <Box component="ul" sx={{ mt: 1, pl: 6 }}>
                            {iPhoneSteps.map((item, index) => (
                                <Box component="li" key={index}>
                                    <Typography variant="body1" color="textPrimary">
                                        {item}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "grid",
                            width: { md: "50%", xs: "100%" },
                            padding: 3,
                            paddingLeft: 5,
                            background: `linear-gradient(145deg, ${colors.grey[800]} 10%, ${colors.grey[900]} 90%)`,
                            borderRadius: "12px",
                            boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.2)",
                        }}
                    >
                        <img src={`../../assets/android-logo.png`}
                            alt="Android Logo"
                            style={{
                                justifySelf: "center",
                                width: "128px",
                                height: "128px",
                                objectFit: "cover", // Ensures the image covers the Box while cropping
                            }}
                        />
                        <Typography
                            variant="h3"
                            sx={{ fontWeight: 700, }}
                        >
                            For Android:
                        </Typography>
                        <Box component="ul" sx={{ mt: 1, pl: 6 }}>
                            {androidSteps.map((item, index) => (
                                <Box component="li" key={index}>
                                    <Typography variant="body1" color="textPrimary">
                                        {item}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>

            </Box>
            {/* Bottom text */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: "center",
                        fontSize: "1rem",
                    }}
                >
                    Once activated, you’ll be connected to the mobile network almost instantly. Embrace the convenience of eSIM technology for your travel and everyday connectivity!
                </Typography>
            </Box>
        </Box>
    );
}

export default WhatIsESIM;