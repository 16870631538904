import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider, Box } from "@mui/material";
import { Routes, Route, useLocation } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Footer from "./scenes/global/Footer";
import Dashboard from "./scenes/dashboard/index.jsx";
import Home from "./scenes/index.jsx"
import Signup from './scenes/signup/signup.jsx'
import Login from "./scenes/login/login.jsx";
import PrivateRoute from './components/PrivateRoute';
import SingleProduct from './components/singleProduct.jsx';
import Checkout from "./components/stripeCheckout.jsx";
import CheckoutStatus from "./components/checkoutStatus.jsx";
import ESIMShop from "./scenes/shop/index.jsx";
import OurStory from "./scenes/pages/our_story.jsx"
import WhyChooseUs from "./scenes/pages/why_us.jsx"
import Impact from "./scenes/pages/impact.jsx"
import TermsAndConditions from "./scenes/pages/TermsAndConditions.jsx";
import PrivacyPolicy from "./scenes/pages/PrivacyPolicy.jsx";
import CookieSettings from "./scenes/pages/cookie.jsx";
import RefundPolicy from "./scenes/pages/refund.jsx";
import SetupGuides from "./scenes/pages/setupGuides.jsx";
import ContactPage from "./scenes/pages/contact.jsx";
import CompatibleDevices from "./scenes/pages/compatibleDevices.jsx";
import WhatIsESIM from "./scenes/pages/whatIsESim.jsx";
import FAQ from "./scenes/pages/faq.jsx";
import Question from "./components/question.jsx";
import AdminLogin from "./scenes/admin/login/login.jsx";
import AdminDashboard from "./scenes/admin/dashboard/index.jsx";
import AdminRoute from "./components/AdminRoute.jsx";

function App() {
  const [theme, colorMode] = useMode();

  // Get the current route
  const location = useLocation();
  // Check if the current path starts with "/admin/"
  const showFooter = !location.pathname.startsWith("/admin/");

  return( 
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* Conditionally render the topbar */}
        {showFooter && <Topbar />}
        <main
          className="content"
          style={{
            justifyContent: 'center',
            height: showFooter ? "calc(96vh - 64px)" : "100vh", // calculate heigt conditionally
            overflowY: 'auto',
            padding: '20px 20px 0px 20px',
          }}>
            <Box sx={{
                        width: {
                            xs: '100%', // Full width for extra-small devices
                            sm: '100%', // Full width for small devices
                            md: '90%',  // 80% width for medium devices
                            lg: '90%',  // 70% width for large devices
                        },
                        margin: '0 auto', // Center the box horizontally
                    }}
          >
            <Routes>
              <Route path="/" element={<Home />} />
              
              <Route path="/dashboard/*" element={<PrivateRoute><Dashboard /> </PrivateRoute>} />
              <Route path='/login' element={<Login/>}></Route>
              <Route path='/signup' element={<Signup/>}></Route>
              <Route path="/product/:id" element={<SingleProduct />} />
              <Route path="/checkout/:id" element={<PrivateRoute><Checkout /></PrivateRoute>} />
              <Route path="/checkout/return" element={<PrivateRoute><CheckoutStatus /></PrivateRoute>} />
              <Route path="/buy-esim" element={<ESIMShop />} />
              <Route path="/page/our-story" element={<OurStory />} />
              <Route path="/page/why-choose-us" element={<WhyChooseUs />} />
              <Route path="/page/impact" element={<Impact />} />
              <Route path="/page/terms" element={<TermsAndConditions />} />
              <Route path="/page/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/page/cookie-settings" element={<CookieSettings />} />
              <Route path="/page/refund-policy" element={<RefundPolicy />} />
              <Route path="/page/contact" element={<ContactPage />} />
              <Route path="/page/what-is-an-esim" element={<WhatIsESIM />} />
              <Route path="/page/compatible-devices" element={<CompatibleDevices />} />
              <Route path="/page/setup-guides" element={<SetupGuides />} />
              <Route path="/page/faq" element={<FAQ />} />
              <Route path="/page/question/:id" element={<Question />} />
              <Route path="/admin/login" element={<AdminLogin />} />
              <Route path="/admin/dashboard/*" element={<AdminRoute><AdminDashboard /></AdminRoute>} />
            </Routes>
          </Box>
          {/* Conditionally render the footer */}
          {showFooter && <Footer />}
          </main>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;