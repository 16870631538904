import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Box, Typography, useTheme, Card, CardHeader, CardContent, Stack, Avatar, Container, Grid2, Paper } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { tokens } from "../../../theme";
import Header from "../../dashboard/Header";
import SalesChart from "./SalesChart";
import ProfitChart from "./ProfitChart";

const Statistics = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [statistics, setStatistics] = useState(null);
    const [dailySalesIncrement, setDailySalesInc] = useState(true);
    const [monthlySalesIncrement, setMonthlySalesIncrement] = useState(true);
    const [dailyProfitIncrement, setDailyProfitInc] = useState(true);
    const [dailyPriceIncrease, setDailyPriceIncrease] = useState(true);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/getstatistics`) // Fetch user data
            .then(response => {
                setStatistics(response.data); // Set user data from the response
                setDailySalesInc(response.data.daily_sales >= response.data.yesterday_sales);
                setMonthlySalesIncrement(response.data.current_month_sales >= response.data.last_month_sales);
                setDailyProfitInc(response.data.today_profit >= response.data.yesterday_profit);
                setDailyPriceIncrease(response.data.today_price >= response.data.yesterday_price);
            })
            .catch(error => {
                console.error("Error fetching user data", error);
            });
    }, []);

    return (
        <Box m={0}>
            <Container
                maxWidth="xl"
                sx={{
                    width: "100%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    boxSizing: "border-box",
                    display: "block",
                    padding: {
                        xs: "16px 16px", // Default padding
                        sm: "24px 24px", // Padding for screens ≥600px
                    },
                }}>
                <Header title="Statistics" subtitle="Sales and Profit Statistics" />
                <Grid2
                    container
                    direction="row"
                    sx={{
                        "--Grid-columns": 12,
                        "--Grid-columnSpacing": "24px",
                        "--Grid-rowSpacing": "24px",
                        flexFlow: "wrap",
                        minWidth: 0,
                        boxSizing: "border-box",
                        display: "flex",
                        margin: "calc(var(--Grid-rowSpacing) / -2) calc(var(--Grid-columnSpacing) / -2)",
                        gap: 0,
                    }}>

                    {/* Daily Sales Card */}
                    <Grid2
                        container
                        direction="row"
                        sx={{
                            flexGrow: 0,
                            flexBasis: "auto",
                            width: {
                                xs: "calc(100% * 12 / var(--Grid-columns))", // 100% width for extra small screens
                                md: "calc(100% * 6 / var(--Grid-columns))",  // 50% width for small screens
                                lg: "calc(100% * 3 / var(--Grid-columns))",  // 25% width for large screens
                            },
                            minWidth: 0,
                            boxSizing: "border-box",
                            padding: "calc(var(--Grid-rowSpacing) / 2) calc(var(--Grid-columnSpacing) / 2)",
                        }}
                    >
                        <Paper
                            elevation={1}
                            sx={{
                                backgroundColor: "background.paper",
                                color: "text.primary",
                                transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                                boxShadow: "rgba(0, 0, 0, 0.04) 0px 5px 22px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                                backgroundImage: "var(--mui-overlays-1)",
                                overflow: "hidden",
                                borderRadius: "20px",
                                height: "100%",
                                width: "100%",
                            }}
                        >
                            <Card sx={{ height: "100%" }}>
                                <CardContent>
                                    <Stack spacing={2}>
                                        <Stack direction="row" justifyContent="space-between">
                                            <Stack spacing={1}>
                                                <Typography
                                                    variant="overline"
                                                    sx={{
                                                        color: colors.grey[500],
                                                        fontWeight: 700,
                                                        fontSize: ".8rem",
                                                        lineHeight: "1.66rem"
                                                    }}
                                                >
                                                    Today Sales
                                                </Typography>
                                                <Typography variant="h4"
                                                    sx={{
                                                        fontSize: "2rem",
                                                    }}
                                                >
                                                    {statistics?.daily_sales ?? `...`}
                                                </Typography>
                                            </Stack>
                                            <Avatar
                                                sx={{
                                                    fontSize: "24px",
                                                    fontWeight: 600,
                                                    letterSpacing: "0px",
                                                    backgroundColor: colors.greenAccent[400],
                                                    height: "56px",
                                                    width: "56px",
                                                }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="1em"
                                                    height="1em"
                                                    fill="currentColor"
                                                    viewBox="0 0 256 256"
                                                    style={{ fontSize: "var(--icon-fontSize-lg)" }}
                                                >
                                                    <path d="M72,104a8,8,0,0,1,8-8h96a8,8,0,0,1,0,16H80A8,8,0,0,1,72,104Zm8,40h96a8,8,0,0,0,0-16H80a8,8,0,0,0,0,16ZM232,56V208a8,8,0,0,1-11.58,7.15L192,200.94l-28.42,14.21a8,8,0,0,1-7.16,0L128,200.94,99.58,215.15a8,8,0,0,1-7.16,0L64,200.94,35.58,215.15A8,8,0,0,1,24,208V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56Zm-16,0H40V195.06l20.42-10.22a8,8,0,0,1,7.16,0L96,199.06l28.42-14.22a8,8,0,0,1,7.16,0L160,199.06l28.42-14.22a8,8,0,0,1,7.16,0L216,195.06Z"></path>
                                                </svg>
                                            </Avatar>
                                        </Stack>
                                        {statistics?.yesterday_sales > 0 &&
                                            <Stack direction="row" alignItems="center" spacing={1}>
                                                {dailySalesIncrement ?
                                                    <ArrowUpwardIcon sx={{ color: colors.greenAccent[500] }} /> :
                                                    <ArrowDownwardIcon sx={{ color: colors.redAccent[500] }} />}
                                                <Typography variant="body2" sx={{ marginLeft: "1px !important", marginTop: "3px !important", color: dailySalesIncrement ? colors.greenAccent[500] : colors.redAccent[500], fontSize: ".9rem" }}>
                                                    {statistics ? Math.floor(((statistics?.daily_sales - statistics?.yesterday_sales) / statistics?.yesterday_sales) * 100) : 0}%
                                                </Typography>
                                                <Typography variant="caption" sx={{ marginTop: "3px !important", color: colors.grey[500], fontSize: "0.75rem" }}>
                                                    compared to yesterday
                                                </Typography>
                                            </Stack>
                                        }
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Paper>
                    </Grid2>

                    {/* Monthly Sales Card */}
                    <Grid2
                        container
                        direction="row"
                        sx={{
                            flexGrow: 0,
                            flexBasis: "auto",
                            width: {
                                xs: "calc(100% * 12 / var(--Grid-columns))", // 100% width for extra small screens
                                md: "calc(100% * 6 / var(--Grid-columns))",  // 50% width for small screens
                                lg: "calc(100% * 3 / var(--Grid-columns))",  // 25% width for large screens
                            },
                            minWidth: 0,
                            boxSizing: "border-box",
                            padding: "calc(var(--Grid-rowSpacing) / 2) calc(var(--Grid-columnSpacing) / 2)",
                        }}
                    >
                        <Paper
                            elevation={1}
                            sx={{
                                backgroundColor: "background.paper",
                                color: "text.primary",
                                transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                                boxShadow: "rgba(0, 0, 0, 0.04) 0px 5px 22px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                                backgroundImage: "var(--mui-overlays-1)",
                                overflow: "hidden",
                                borderRadius: "20px",
                                height: "100%",
                                width: "100%",
                            }}
                        >
                            <Card sx={{ height: "100%" }}>
                                <CardContent>
                                    <Stack spacing={2}>
                                        <Stack direction="row" justifyContent="space-between">
                                            <Stack spacing={1}>
                                                <Typography
                                                    variant="overline"
                                                    sx={{
                                                        color: colors.grey[500],
                                                        fontWeight: 700,
                                                        fontSize: ".8rem",
                                                        lineHeight: "1.66rem"
                                                    }}
                                                >
                                                    Monthly Sales
                                                </Typography>
                                                <Typography variant="h4"
                                                    sx={{
                                                        fontSize: "2rem",
                                                    }}
                                                >
                                                    {statistics?.current_month_sales ?? `...`}
                                                </Typography>
                                            </Stack>
                                            <Avatar
                                                sx={{
                                                    fontSize: "24px",
                                                    fontWeight: 600,
                                                    letterSpacing: "0px",
                                                    backgroundColor: colors.blueAccent[400],
                                                    height: "56px",
                                                    width: "56px",
                                                }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="1em"
                                                    height="1em"
                                                    fill="currentColor"
                                                    viewBox="0 0 256 256"
                                                    style={{ fontSize: "var(--icon-fontSize-lg)" }}
                                                >
                                                    <path d="M72,104a8,8,0,0,1,8-8h96a8,8,0,0,1,0,16H80A8,8,0,0,1,72,104Zm8,40h96a8,8,0,0,0,0-16H80a8,8,0,0,0,0,16ZM232,56V208a8,8,0,0,1-11.58,7.15L192,200.94l-28.42,14.21a8,8,0,0,1-7.16,0L128,200.94,99.58,215.15a8,8,0,0,1-7.16,0L64,200.94,35.58,215.15A8,8,0,0,1,24,208V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56Zm-16,0H40V195.06l20.42-10.22a8,8,0,0,1,7.16,0L96,199.06l28.42-14.22a8,8,0,0,1,7.16,0L160,199.06l28.42-14.22a8,8,0,0,1,7.16,0L216,195.06Z"></path>
                                                </svg>
                                            </Avatar>
                                        </Stack>
                                        {statistics?.last_month_sales > 0 &&
                                            <Stack direction="row" alignItems="center" spacing={1}>
                                                {monthlySalesIncrement ?
                                                    <ArrowUpwardIcon sx={{ color: colors.greenAccent[500] }} /> :
                                                    <ArrowDownwardIcon sx={{ color: colors.redAccent[500] }} />}
                                                <Typography variant="body2" sx={{ marginLeft: "1px !important", marginTop: "3px !important", color: monthlySalesIncrement ? colors.greenAccent[500] : colors.redAccent[500], fontSize: ".9rem" }}>
                                                    {statistics ? Math.floor(((statistics?.current_month_sales - statistics?.last_month_sales) / statistics?.last_month_sales) * 100) : 0}%
                                                </Typography>
                                                <Typography variant="caption" sx={{ marginTop: "3px !important", color: colors.grey[500], fontSize: "0.75rem" }}>
                                                    compared to last month
                                                </Typography>
                                            </Stack>
                                        }
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Paper>
                    </Grid2>

                    {/* Daily Profit Card */}
                    <Grid2
                        container
                        direction="row"
                        sx={{
                            flexGrow: 0,
                            flexBasis: "auto",
                            width: {
                                xs: "calc(100% * 12 / var(--Grid-columns))", // 100% width for extra small screens
                                md: "calc(100% * 6 / var(--Grid-columns))",  // 50% width for small screens
                                lg: "calc(100% * 3 / var(--Grid-columns))",  // 25% width for large screens
                            },
                            minWidth: 0,
                            boxSizing: "border-box",
                            padding: "calc(var(--Grid-rowSpacing) / 2) calc(var(--Grid-columnSpacing) / 2)",
                        }}
                    >
                        <Paper
                            elevation={1}
                            sx={{
                                backgroundColor: "background.paper",
                                color: "text.primary",
                                transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                                boxShadow: "rgba(0, 0, 0, 0.04) 0px 5px 22px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                                backgroundImage: "var(--mui-overlays-1)",
                                overflow: "hidden",
                                borderRadius: "20px",
                                height: "100%",
                                width: "100%",
                            }}
                        >
                            <Card sx={{ height: "100%" }}>
                                <CardContent>
                                    <Stack spacing={2}>
                                        <Stack direction="row" justifyContent="space-between">
                                            <Stack spacing={1}>
                                                <Typography
                                                    variant="overline"
                                                    sx={{
                                                        color: colors.grey[500],
                                                        fontWeight: 700,
                                                        fontSize: ".8rem",
                                                        lineHeight: "1.66rem"
                                                    }}
                                                >
                                                    Today Profit
                                                </Typography>
                                                <Typography variant="h4"
                                                    sx={{
                                                        fontSize: "2rem",
                                                    }}
                                                >
                                                    ${statistics?.today_profit ?? `...`}
                                                </Typography>
                                            </Stack>
                                            <Avatar
                                                sx={{
                                                    fontSize: "24px",
                                                    fontWeight: 600,
                                                    letterSpacing: "0px",
                                                    backgroundColor: colors.greenAccent[400],
                                                    height: "56px",
                                                    width: "56px",
                                                }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="1em"
                                                    height="1em"
                                                    fill="currentColor"
                                                    viewBox="0 0 256 256"
                                                    style={{ fontSize: "var(--icon-fontSize-lg)" }}
                                                >
                                                    <path d="M152,120H136V56h8a32,32,0,0,1,32,32,8,8,0,0,0,16,0,48.05,48.05,0,0,0-48-48h-8V24a8,8,0,0,0-16,0V40h-8a48,48,0,0,0,0,96h8v64H104a32,32,0,0,1-32-32,8,8,0,0,0-16,0,48.05,48.05,0,0,0,48,48h16v16a8,8,0,0,0,16,0V216h16a48,48,0,0,0,0-96Zm-40,0a32,32,0,0,1,0-64h8v64Zm40,80H136V136h16a32,32,0,0,1,0,64Z"></path>
                                                </svg>
                                            </Avatar>
                                        </Stack>
                                        {statistics?.yesterday_profit > 0 &&
                                            <Stack direction="row" alignItems="center" spacing={1}>
                                                {dailyProfitIncrement ?
                                                    <ArrowUpwardIcon sx={{ color: colors.greenAccent[500] }} /> :
                                                    <ArrowDownwardIcon sx={{ color: colors.redAccent[500] }} />}
                                                <Typography variant="body2" sx={{ marginLeft: "1px !important", marginTop: "3px !important", color: dailyProfitIncrement ? colors.greenAccent[500] : colors.redAccent[500], fontSize: ".9rem" }}>
                                                    {statistics ? Math.floor(((statistics?.today_profit - statistics?.yesterday_profit) / statistics?.yesterday_profit) * 100) : 0}%
                                                </Typography>
                                                <Typography variant="caption" sx={{ marginTop: "3px !important", color: colors.grey[500], fontSize: "0.75rem" }}>
                                                    compared to yesterday
                                                </Typography>
                                            </Stack>
                                        }
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Paper>
                    </Grid2>

                    {/* Daily Actual Price Card */}
                    <Grid2
                        container
                        direction="row"
                        sx={{
                            flexGrow: 0,
                            flexBasis: "auto",
                            width: {
                                xs: "calc(100% * 12 / var(--Grid-columns))", // 100% width for extra small screens
                                md: "calc(100% * 6 / var(--Grid-columns))",  // 50% width for small screens
                                lg: "calc(100% * 3 / var(--Grid-columns))",  // 25% width for large screens
                            },
                            minWidth: 0,
                            boxSizing: "border-box",
                            padding: "calc(var(--Grid-rowSpacing) / 2) calc(var(--Grid-columnSpacing) / 2)",
                        }}
                    >
                        <Paper
                            elevation={1}
                            sx={{
                                backgroundColor: "background.paper",
                                color: "text.primary",
                                transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                                boxShadow: "rgba(0, 0, 0, 0.04) 0px 5px 22px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                                backgroundImage: "var(--mui-overlays-1)",
                                overflow: "hidden",
                                borderRadius: "20px",
                                height: "100%",
                                width: "100%",
                            }}
                        >
                            <Card sx={{ height: "100%" }}>
                                <CardContent>
                                    <Stack spacing={2}>
                                        <Stack direction="row" justifyContent="space-between">
                                            <Stack spacing={1}>
                                                <Typography
                                                    variant="overline"
                                                    sx={{
                                                        color: colors.grey[500],
                                                        fontWeight: 700,
                                                        fontSize: ".8rem",
                                                        lineHeight: "1.66rem"
                                                    }}
                                                >
                                                    Today Cost
                                                </Typography>
                                                <Typography variant="h4"
                                                    sx={{
                                                        fontSize: "2rem",
                                                    }}
                                                >
                                                    ${statistics?.today_price ?? `...`}
                                                </Typography>
                                            </Stack>
                                            <Avatar
                                                sx={{
                                                    fontSize: "24px",
                                                    fontWeight: 600,
                                                    letterSpacing: "0px",
                                                    backgroundColor: colors.blueAccent[400],
                                                    height: "56px",
                                                    width: "56px",
                                                }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="1em"
                                                    height="1em"
                                                    fill="currentColor"
                                                    viewBox="0 0 256 256"
                                                    style={{ fontSize: "var(--icon-fontSize-lg)" }}
                                                >
                                                    <path d="M152,120H136V56h8a32,32,0,0,1,32,32,8,8,0,0,0,16,0,48.05,48.05,0,0,0-48-48h-8V24a8,8,0,0,0-16,0V40h-8a48,48,0,0,0,0,96h8v64H104a32,32,0,0,1-32-32,8,8,0,0,0-16,0,48.05,48.05,0,0,0,48,48h16v16a8,8,0,0,0,16,0V216h16a48,48,0,0,0,0-96Zm-40,0a32,32,0,0,1,0-64h8v64Zm40,80H136V136h16a32,32,0,0,1,0,64Z"></path>
                                                </svg>
                                            </Avatar>
                                        </Stack>
                                        {statistics?.yesterday_price > 0 &&
                                            <Stack direction="row" alignItems="center" spacing={1}>
                                                {dailyPriceIncrease ?
                                                    <ArrowUpwardIcon sx={{ color: colors.greenAccent[500] }} /> :
                                                    <ArrowDownwardIcon sx={{ color: colors.redAccent[500] }} />}
                                                <Typography variant="body2" sx={{ marginLeft: "1px !important", marginTop: "3px !important", color: dailyPriceIncrease ? colors.greenAccent[500] : colors.redAccent[500], fontSize: ".9rem" }}>
                                                    {statistics ? Math.floor(((statistics?.today_price - statistics?.yesterday_price) / statistics?.yesterday_price) * 100) : 0}%
                                                </Typography>
                                                <Typography variant="caption" sx={{ marginTop: "3px !important", color: colors.grey[500], fontSize: "0.75rem" }}>
                                                    compared to yesterday
                                                </Typography>
                                            </Stack>
                                        }
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Paper>
                    </Grid2>

                    {/* Sales Chart */}
                    <Grid2
                        sx={{
                            flexGrow: 0,
                            flexBasis: "auto",
                            width: "100%",
                            minWidth: 0,
                            boxSizing: "border-box",
                            padding: "calc(var(--Grid-rowSpacing) / 2) calc(var(--Grid-columnSpacing) / 2)",
                        }}
                    >
                        <Paper
                            elevation={1}
                            sx={{
                                backgroundColor: "background.paper",
                                color: "text.primary",
                                transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                                boxShadow: "rgba(0, 0, 0, 0.04) 0px 5px 22px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                                backgroundImage: "var(--mui-overlays-1)",
                                overflow: "hidden",
                                borderRadius: "20px",
                                height: "100%",
                            }}
                        >
                            <Card>
                                <CardHeader
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "32px 24px 16px",
                                    }}
                                    title={
                                        <Typography
                                            variant="h5"
                                            sx={{
                                                flex: 1,
                                            }}
                                        >
                                            Sales
                                        </Typography>
                                    }
                                />
                                <CardContent
                                    sx={{
                                        padding: "32px 24px",
                                    }}
                                >
                                    {(statistics?.chartData.length > 0) && 
                                    <SalesChart chartData={statistics.chartData} />
                                    }
                                </CardContent>
                            </Card>
                        </Paper>
                    </Grid2>

                    {/* Profit Chart */}
                    <Grid2
                        sx={{
                            flexGrow: 0,
                            flexBasis: "auto",
                            width: "100%",
                            minWidth: 0,
                            boxSizing: "border-box",
                            padding: "calc(var(--Grid-rowSpacing) / 2) calc(var(--Grid-columnSpacing) / 2)",
                        }}
                    >
                        <Paper
                            elevation={1}
                            sx={{
                                backgroundColor: "background.paper",
                                color: "text.primary",
                                transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                                boxShadow: "rgba(0, 0, 0, 0.04) 0px 5px 22px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                                backgroundImage: "var(--mui-overlays-1)",
                                overflow: "hidden",
                                borderRadius: "20px",
                                height: "100%",
                            }}
                        >
                            <Card>
                                <CardHeader
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "32px 24px 16px",
                                    }}
                                    title={
                                        <Typography
                                            variant="h5"
                                            sx={{
                                                flex: 1,
                                            }}
                                        >
                                            Profit
                                        </Typography>
                                    }
                                />
                                <CardContent
                                    sx={{
                                        padding: "32px 24px",
                                    }}
                                >
                                    {(statistics?.chartData.length > 0) && 
                                    <ProfitChart chartData={statistics.chartData} />
                                    }
                                </CardContent>
                            </Card>
                        </Paper>
                    </Grid2>


                </Grid2>
            </Container>
        </Box>
    );
}

export default Statistics;

