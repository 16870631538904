import React from 'react';
import { Box, Typography, Divider, List, ListItem, ListItemText } from '@mui/material';
import './TermsAndConditions.css';

const TermsAndConditions = () => {

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="center" alignItems="center">
                <Box className="termsAndConditions">
                    <Typography variant="h2" fontWeight={700} mb={3}>
                        Terms Of Service
                    </Typography>
                    <Typography mb={2} textAlign="left" width="100%">
                        <strong>Last updated: </strong>29/11/2024
                    </Typography>
                    <Typography mb={3} textAlign="justify">
                        By using the Travel Data eSIM website, you enter into a legally binding agreement with Travel Data eSIM. Your continued use of the Platform indicates your understanding and acceptance of the terms listed below. Please review these Terms and Conditions regularly, as updates may be made periodically without prior notification.
                        <br />
                        <br />
                        Additional terms and conditions or documents that are occasionally published on the Application are also considered a part of these Terms and Conditions. At our discretion, we may make changes or modifications to these Terms and Conditions at any time and for any reason. We may notify you of any changes by updating the “Last updated” date of these Terms and Conditions, and you acknowledge that you do not have the right to receive individual notice for each change. It is your responsibility to regularly review these Terms and Conditions to remain informed about any updates. By continuing to use the Application after any revised Terms are published, you will be subject to and deemed to have accepted these changes.
                    </Typography>

                    <Box className="serviceLeadingSection">
                        <Typography variant="h5">
                            <span className={`sn blue`}>1.</span>
                            <span className={`st blue`}>eSIM Service</span>
                        </Typography>
                        <Typography className="spl">
                            Travel Data eSIM provides prepaid eSIM plans offering reliable data services for travelers worldwide. Plans are available for purchase directly via our website, or through authorized partners and agents.
                        </Typography>
                    </Box>

                    <Box className="serviceLeadingSection">
                        <Typography variant="h5">
                            <span className={`sn orange`}>2.</span>
                            <span className={`st orange`}>Terms of service</span>
                        </Typography>
                        <Box className="serviceInfoContainer">
                            <Typography className="serviceLead">Registration and Account Requirements </Typography>
                            <Typography className="serviceDetails">To access our services, users must create an account by providing accurate details such as: </Typography>
                            <Divider
                                className={`secionLine lineColorOrange`}
                            />
                            <List className='serviceDetails'>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                    <ListItemText primary={`Full Name (First and Last Name). `} />
                                </ListItem>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                    <ListItemText primary={`A valid and verifiable email address. `} />
                                </ListItem>
                            </List>
                            <Typography className="serviceDetails">Users can also upload profile photos and travel preferences, which are optional and entirely at their discretion. </Typography>
                            <Typography className="serviceLead">By registering, you:  </Typography>
                            <List className='serviceDetails'>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                    <ListItemText primary={<>Agree to Travel Data eSIM’s <a
                                        href={`/page/terms`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ textDecoration: 'underline', color: 'inherit' }}
                                    >Terms and Conditions</a> and <a
                                        href={`/page/privacy-policy`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ textDecoration: 'underline', color: 'inherit' }}
                                    >Privacy Policies</a>. </>} />
                                </ListItem>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                    <ListItemText primary={`Accept responsibility for all account activities, including safeguarding your password. `} />
                                </ListItem>
                            </List>
                        </Box>
                        <Box className="serviceInfoContainer">
                            <Typography className="serviceLead">Purchase and Activation </Typography>
                            <Divider
                                className={`secionLine lineColorOrange`}
                            />
                            <List className='serviceDetails'>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                    <ListItemText primary={`Customers can find, choose, and buy our plans from our website. Plans are subject to availability based on your location. `} />
                                </ListItem>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                    <ListItemText primary={`After making a purchase, customers must download the eSIM onto their device in order for the data plans to be configured according to their order. Once downloaded, the eSIM will remain on the device even after the plan has been used up or expired. Customers have the option to remove the eSIM in their phone settings at any time, but it is advisable not to do so until the plan has been fully consumed or expired. `} />
                                </ListItem>
                            </List>
                            <Typography className="serviceLead">Please note: </Typography>
                            <List className='serviceDetails'>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                    <ListItemText primary={`eSIMs remain stored on devices post-expiry and can be removed manually in your phone settings. `} />
                                </ListItem>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                    <ListItemText primary={`Ensure that your device supports eSIM technology for compatibility with our plans. `} />
                                </ListItem>
                            </List>
                        </Box>
                        <Box className="serviceInfoContainer">
                            <Typography className="serviceLead">Service Duration and Termination </Typography>
                            <Divider
                                className={`secionLine lineColorOrange`}
                            />
                            <List className='serviceDetails'>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                    <ListItemText primary={`Services start once the eSIM is downloaded and activated. `} />
                                </ListItem>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                    <ListItemText primary={`Services end upon full data consumption or plan expiration, whichever occurs first. `} />
                                </ListItem>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                    <ListItemText primary={`Travel Data eSIM reserves the right to suspend or terminate accounts for violations of terms, misuse of promotions, or unlawful activities. `} />
                                </ListItem>
                            </List>
                        </Box>
                    </Box>

                    <Box className="serviceLeadingSection">
                        <Typography variant="h5">
                            <span className={`sn lightGreen`}>3.</span>
                            <span className={`st lightGreen`}>Payments and Taxes</span>
                        </Typography>
                        <List className='spl'>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={`All eSIM purchases are prepaid. `} />
                            </ListItem>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={`The prices displayed include applicable indirect taxes unless otherwise stated. `} />
                            </ListItem>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={`Indirect taxes (such as VAT or sales tax) are calculated based on your billing address during checkout. `} />
                            </ListItem>
                        </List>
                    </Box>

                    <Box className="serviceLeadingSection">
                        <Typography variant="h5">
                            <span className={`sn purple`}>4.</span>
                            <span className={`st purple`}>Refund and Complaint Policy </span>
                        </Typography>
                        <Typography className="spl">
                            Customers will be charged upon the purchase of eSIM.
                            <br />
                            <br />
                            Refunds are permitted only before eSIM activation or in the event of a technical issue originating from Travel Data eSIM.
                            <br />
                            <br />
                            Any complaints or feedback about the Service can be sent to our WhatsApp channel, send us a message on our website, to the support team via a email {`<email_address>`}
                        </Typography>
                    </Box>

                    <Box className="serviceLeadingSection">
                        <Typography variant="h5">
                            <span className={`sn blue`}>5.</span>
                            <span className={`st blue`}>Legal Compliance </span>
                        </Typography>
                        <Typography className="spl">
                            Travel Data eSIM adheres to all applicable laws and regulations. By using our services, you agree to:
                        </Typography>
                        <List className='spl'>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={`Comply with the laws of your country of residence and the countries you visit. `} />
                            </ListItem>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={`Provide accurate personal information as required for regulatory compliance. `} />
                            </ListItem>
                        </List>
                        <Typography className="spl">
                            We may be required to collect certain personal information from you in order to comply with legal and regulatory requirements. If we request such information from you and indicate that this is for legal or regulatory compliance, then you agree that you will provide us with the true and accurate information requested. Our use of your personal information is subject to the terms of our Privacy Policy (see below).
                        </Typography>
                    </Box>

                    <Box className="serviceLeadingSection">
                        <Typography variant="h5">
                            <span className={`sn orange`}>6.</span>
                            <span className={`st orange`}>Intellectual Property </span>
                        </Typography>
                        <Typography className="spl">
                            Except as otherwise indicated, the Web and Application is our proprietary property and all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Application (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the “Marks”) are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual property rights and unfair competition laws.
                            <br />
                            <br />
                            You are permitted to access and use the Web Application if you meet the eligibility requirements. This grants you a restricted license to use the Application and to save or print any part of the Content for your personal, non-commercial purposes. All other rights to the Website, Application, Content, and Marks are reserved by us and not explicitly granted to you.
                        </Typography>
                    </Box>

                    <Box className="serviceLeadingSection">
                        <Typography variant="h5">
                            <span className={`sn lightGreen`}>7.</span>
                            <span className={`st lightGreen`}>Customer Obligations </span>
                        </Typography>
                        <Typography className="spl">
                            By using Travel Data eSIM services, you agree to:
                        </Typography>
                        <List className='spl'>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={`The customer acknowledges and agrees to adhere to the Terms and Conditions while using the Service and takes full responsibility for any and all usage of the Service. `} />
                            </ListItem>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={`Customers promise to provide truthful, precise, and comprehensive registration details, and to regularly update this information. `} />
                            </ListItem>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={`The customer agrees that the Service should be used for personal consumption only. `} />
                            </ListItem>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={`The customer agrees to abide by all relevant laws and regulations of their home country, country of residence, and any country they may be visiting while using the Service. `} />
                            </ListItem>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={`The customer acknowledges the importance of maintaining the confidentiality of their password and accepts responsibility for any activity on their account using the password. We reserve the right to terminate, regain, or modify a chosen username if we deem it inappropriate, at our sole discretion. `} />
                            </ListItem>
                            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}><span className='bulletPoint'>•</span>
                                <ListItemText primary={`Customers agree that the Service shall not be used to engage in any action that is abusive, illegal or fraudulent or that causes the Network to be impaired or damaged. `} />
                            </ListItem>
                        </List>
                    </Box>

                </Box>
            </Box>
        </Box>
    );
};

export default TermsAndConditions;
