import { Routes, Route } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import DashboardContent from "./DashboardContent";
import Order from "../orders";
import Profile from "../profile";
import CheckUsage from "../check_usage";
import { Box } from "@mui/material";

const Dashboard = () => {
  return (
    <Box sx={{  width: {
            xs: '100%', // Full width for extra-small devices
            sm: '100%', // Full width for small devices
            md: '80%',  // 80% width for medium devices
            lg: '80%',  // 80% width for large devices
        }, margin: "0 auto", minHeight: "70vh" }}>

    <div style={{ display: "flex" }}>
      <Sidebar />
      <div style={{ flexGrow: 1, display: "grid" }}>
        <Header />
        <Routes>
          <Route path="/" element={<DashboardContent />} />
          <Route path="/order" element={<Order />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/check_usage" element={<CheckUsage />} />
          {/* Add more dashboard routes as needed */}
        </Routes>
      </div>
    </div>
    </Box>
  );
};

export default Dashboard;
