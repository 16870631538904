import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";

const Question = () => {
    const { id } = useParams();
    const sectionId = id.split('-')[0]; // Extract section ID
    const questionId = id.split('-')[1]; // Extract question ID
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [Section, setSection] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {

        const fetchSection = async (id) => {
            const filename = `questionsSection${id}.js`;
            try {
                const module = await import(`../data/${filename}`);
                setSection(module.Section);
            } catch (err) {
                setError(err.message);
            }
            finally{
                setLoading(false);
            }
        };
        fetchSection(sectionId);
    }, [sectionId]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error fetching question: {error}</div>;

    return (
        <Box
            sx={{
                minHeight: "100vh",
                padding: "40px 40px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "40px",
            }}
        >

            {/* Header Section */}
            <Box
                sx={{
                    background: colors.blueAccent[900],
                    width: "100%",
                    padding: 2,
                    margin: -6,
                }}
            >
                <Typography
                    sx={{
                        fontSize: "1rem",
                        color: colors.grey[500],
                    }}
                >/
                    <Typography
                        component="a"
                        href='/page/faq'
                        sx={{
                            textDecoration: "none",
                            fontSize: "1rem",
                            color: colors.grey[500],
                        }}
                    > FAQ </Typography>
                    /
                    <Typography
                        component="a"
                        href={'/page/faq#' + Section.name.replace(/ /g, "-")}
                        sx={{
                            textDecoration: "none",
                            fontSize: "1rem",
                            color: colors.grey[500],
                        }}
                    > {Section.name} </Typography>
                </Typography>
            </Box>
            <Box
                sx={{
                    mt: 4,
                    padding: "20px 0 0 20px",
                    maxWidth: "1200",
                    width: "100%"
                }}
            >
                <Typography
                    variant="h2"
                    sx={{
                        fontWeight: 500,
                        marginBottom: "10px",
                        color: colors.grey[100],
                    }}
                >
                    {Section.questions[questionId-1].q}
                </Typography>
                <Typography
                    variant='body1'
                    sx={{
                        mt: 3,
                        color: colors.grey[400],
                        fontSize: "1rem",
                    }}
                >
                    {Section.questions[questionId-1].a}
                </Typography>
            </Box>
        </Box>
    );
}

export default Question;