import React, { useEffect } from "react";

const ProfitChart = ({ chartData }) => {
    const ApexCharts = window.ApexCharts;

    // Step 1: Generate last 30 days' dates
    const last30Days = Array.from({ length: 30 }, (_, i) => {
        const date = new Date();
        date.setDate(date.getDate() - i);
        return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
    }).reverse();

    // Step 2: Map chartData to last 30 days with defaults
    const dailyProfitData = last30Days.map(date => {
        const record = chartData.find(
            d => d.record_date.split("T")[0] === date
        );
        return record ? record.daily_profit : 0;
    });

    useEffect(() => {
        // Only initialize the chart after data is ready
        if (dailyProfitData.length > 0 && last30Days.length > 0) {
            const maxDailyProfit = Math.max(...dailyProfitData);
            const options = {
                chart: {
                    type: "bar", // Or "bar" if preferred
                },
                series: [
                    {
                        name: "Profit",
                        data: dailyProfitData,
                    },
                ],
                xaxis: {
                    categories: last30Days, // Dates as x-axis labels
                },
                yaxis: {
                    max: Math.ceil(maxDailyProfit * 1.1), // Add 10% buffer
                    labels: {
                        formatter: (value) => `$${value.toFixed(2)}`, // Format with dollar sign
                    },
                },
                title: {
                    text: "Daily Profit for Last 30 Days",
                },
                dataLabels: {
                    enabled: false, // Disable the data labels on the bars
                },
            };

            const chart = new ApexCharts(document.querySelector("#profitchart"), options);
            chart.render();

            // Cleanup the chart on unmount
            return () => {
                chart.destroy();
            };
        }
    }, [dailyProfitData, last30Days, ApexCharts]);

    return <div id="profitchart"></div>;
};

export default ProfitChart;
