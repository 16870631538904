import React, { useEffect, useState } from "react";
import { Button, Box, Typography, useTheme, CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../../theme";  // Assuming you have this for theme tokens
import Header from "../../dashboard/Header";  // Assuming Header component exists

const Orders = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/admin/orders/`);
                if (!response.ok) {
                    throw new Error("Failed to fetch orders");
                }
                const data = await response.json();

                // Transform data to match required format
                const transformedData = data.map((order) => ({
                    id: order.order_id,
                    user_id: order.user_id,
                    date: new Date(order.created_at).toLocaleDateString(),
                    packageName: order.package_name || "N/A",
                    actualPrice: `$${order.actual_price.toFixed(2)}`,
                    profit: `$${order.profit.toFixed(2)}`,
                    totalPrice: `$${order.total_price.toFixed(2)}`,
                    transactionId: order.transaction_id,
                }));
                setOrders(transformedData);
            } catch (error) {
                console.error("Error fetching orders:", error.message);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchOrders();
    }, []);

    const handleDownload = async (orderId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/user/orders/${orderId}/qr`);
            if (!response.ok) {
                throw new Error("Failed to fetch download file");
            }

            const blob = await response.blob();
            const downloadUrl = URL.createObjectURL(blob);

            const link = document.createElement("a");
            link.href = downloadUrl;
            link.download = `Order${orderId}.zip`;

            link.click();
            URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error("Error downloading file:", error.message);
        }
    };

    const columns = [
        { field: "id", headerName: "Order ID", width: 120 },
        { field: "user_id", headerName: "User ID", width: 120 },
        { field: "date", headerName: "Date", width: 130 },
        { field: "packageName", headerName: "Package Name", flex: 1 },
        { field: "actualPrice", headerName: "Actual Price", width: 150 },
        { field: "profit", headerName: "Profit", width: 120 },
        { field: "totalPrice", headerName: "Total Price", width: 150 },
        { field: "transactionId", headerName: "Transaction ID", width: 180 },
        {
            field: "actions",
            headerName: "Actions",
            width: 200,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: '#3da58a',
                        color: 'white',
                        fontSize: '0.68rem',
                        fontWeight: 'bold',
                        textTransform: 'none',
                        borderRadius: '8px',
                        padding: '8px 8px',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                        transition: 'background-color 0.3s ease',
                        '&:hover': {
                            backgroundColor: '#319177',
                        },
                        margin: '2px',
                    }}
                    onClick={(event) => {
                        event.stopPropagation();  // Prevent row selection
                        handleDownload(params.row.id);
                    }}
                >
                    Download QR
                </Button>
            ),
        },
    ];

    return (
        <Box m="20px">
            <Header title="Orders" subtitle="All the orders have been made" />
            <Box
                m="40px 0 0 0"
                height="65vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                        outline: "none",
                    },
                    "& .MuiDataGrid-columnHeader": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiDataGrid-cell:focus-visible": {
                        outline: "none",
                        boxShadow: "none",
                    },
                    "& .MuiButton-root:focus": {
                        outline: "none",
                        boxShadow: "none",
                    },
                }}
            >
                {loading ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                    >
                        <CircularProgress color="secondary" />
                    </Box>
                ) : error ? (
                    <Typography variant="h6" color="error" textAlign="center">
                        {error}
                    </Typography>
                ) : orders.length > 0 ? (
                    <DataGrid
                        rows={orders}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[5, 10, 20]}
                        disableSelectionOnClick
                        autoHeight
                        getRowId={(row) => row.id}
                    />
                ) : (
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        textAlign="left"
                    >
                        <img
                            src={`../../assets/no_orders_yet_searching.png`}
                            alt="No data available"
                            style={{ width: "25%", marginRight: "20px" }}
                        />
                        <Box>
                            <Typography variant="h2" color={colors.grey[100]}>
                                No Orders Available
                            </Typography>
                            <Typography variant="subtitle1" color={colors.grey[300]}>
                                Currently, there are no orders made by you
                            </Typography>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default Orders;
